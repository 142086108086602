import { ContentLayout } from '@/components/Layout';
import { Authorization, ROLES } from '@/lib/authorization';

import { PhonesList } from '../components/PhonesList';

export const Phones = () => {
  return (
    <ContentLayout title="Phones">
      <div className="mt-4">
        <Authorization
          forbiddenFallback={<div>Only admin can view this.</div>}
          allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
          <PhonesList />
        </Authorization>
      </div>
    </ContentLayout>
  );
};
