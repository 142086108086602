/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { useState } from 'react';

import { PhonesList } from '@/features/users';

import { OperatorsTable } from './OperatorsTable';
import { Tabs } from './Tabs';

export type DrawerPanelProps = {
  className?: string;
  open: boolean;
  toggle: any;
  totalUsers: number;
  totalPhones: number;
};

export const DrawerPanel: React.FC<DrawerPanelProps> = ({
  open,
  toggle,
  totalUsers,
  totalPhones,
}) => {
  const handleToggle = () => {
    toggle((prev) => !prev);
  };
  const [opened, setOpened] = useState('1');
  return (
    <div
      className={cn(
        open
          ? 'h-[600px] max-h-[300px] md:max-h-[500px]'
          : 'h-[60px] max-h-[60px]',
        'h-100% relative  w-full border-t-2 border-black py-4 pt-0',
      )}
      style={{
        transition: 'max-height 0.25s ease-in',
      }}>
      <div
        className="absolute left-[47%] top-[-15px] cursor-pointer rounded-full border bg-slate-500 md:left-[49%]"
        onClick={handleToggle}>
        {open ? (
          <ChevronDownIcon
            className="h-6 w-6"
            aria-hidden="true"
            color="white"
          />
        ) : (
          <ChevronUpIcon className="h-6 w-6" aria-hidden="true" color="white" />
        )}
      </div>
      <div className="flex w-full justify-center align-middle">
        <Tabs
          opened={opened}
          setOpened={setOpened}
          toggleDrawer={() => {
            if (!open) {
              handleToggle();
            }
          }}>
          <>
            <div
              className="active show tab-pane fade"
              id="tabs-operators"
              role="tabpanel">
              {opened === '1' && open && (
                <OperatorsTable name="operators" total={totalUsers} />
              )}
            </div>
            <div className="fade tab-pane" id="tabs-devices" role="tabpanel">
              {opened === '2' && <PhonesList total={totalPhones} />}
            </div>
          </>
        </Tabs>
      </div>
    </div>
  );
};
