import { ContentLayout } from '@/components/Layout';
import {
  useCreateModuleMutation,
  useCreateOperationMutation,
  useCreateOperatorMutation,
} from '@/generated/graphql';
import { Authorization, ROLES } from '@/lib/authorization';

export const Sessions = () => {
  const [createModule] = useCreateModuleMutation();
  const [createOperation] = useCreateOperationMutation();
  const [createOperator] = useCreateOperatorMutation();

  const handleCreateModules = async () => {
    const modules = {};
    const data = Object.values(modules);
    console.log({ data });
    try {
      // data.forEach(async (data, i) => {
      //   await createOperation({
      //     variables: {
      //       data: {
      //         color: Color.Gray,
      //         completed: 0,
      //         currentHour: data.currentHour ?? 0,
      //         goal: data.goalReference,
      //         goalReference: data.goalReference,
      //         isReference: i === 0,
      //         moduleId: 'clf5uixsw003ds74k9f8opu5f',
      //         name: data?.name ?? `8.${i + 1}`,
      //         onBreak: false,
      //         operatorActive: false,
      //         position: i,
      //         remaining: data.remaining,
      //         startedLate: false,
      //         total: data.total,
      //         totalCompleted: data?.totalCompleted ?? 0,
      //       },
      //     },
      //   });
      // });
      console.log('listo');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ContentLayout title="Sessions">
      <div className="mt-4">
        <Authorization
          forbiddenFallback={<div>Only admin can view this.</div>}
          allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
          {/* <SessionsList /> */}
        </Authorization>
        <button onClick={handleCreateModules}>importar modulos</button>
      </div>
    </ContentLayout>
  );
};
