import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

//https://www.api.mariolugo.org/
const K8S_API = 'https://www.api.mariolugo.org/graphql';
const K8S_WS = 'wss://www.api.mariolugo.org/graphql';
const SOCKET_IO_K8S = 'https://www.api.mariolugo.org';
const API = process.env.REACT_APP_API;
const WS_API = process.env.REACT_APP_WS;
const SOCKET_IO_LOCAL = 'http://localhost:3421';

const isProd = true;

export const CURRENT_API = {
  API: isProd ? K8S_API : API,
  WS: isProd ? K8S_WS : WS_API,
  API_SINGLE: isProd ? SOCKET_IO_K8S : SOCKET_IO_LOCAL,
  SOCKET_IO: isProd ? SOCKET_IO_K8S : SOCKET_IO_LOCAL,
  API_2: 'http://127.0.0.1:3422',
};

export const SOCKET_IO_CONFIG = {
  transports: ['websocket', 'polling', 'flashsocket'],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000, // in milliseconds
  reconnectionDelayMax: 5000, // in milliseconds
};

const httpLink = new HttpLink({
  uri: CURRENT_API.API, //backend link, check backend console for link
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: CURRENT_API.WS as string, // backend link, check backend console for link
    retryAttempts: 100,
    shouldRetry: () => true,
    lazy: true,
  }),
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('ConfecTronic-Token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // web socket connection for subscriptions
  authLink.concat(httpLink), // http connection for query and mutation
);

// setting up apollo client with the server http and websocket links
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(), // for in memory caching of data
});

export default client;
