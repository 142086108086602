import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'number';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  value?: any;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, className, registration, error, value } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        defaultValue={value}
        className={clsx(
          'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm',
          className,
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
