import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { CURRENT_API } from '@/client';
import { useClient } from '@/hooks/useClient';
import { useNotificationStore } from '@/stores/notifications';

export const useCreateOrganization = () => {
  const client = useClient({
    url: CURRENT_API.API_2,
  });

  const fn = async (payload) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return await client.createOrganization(payload);
  };

  const onError = useCallback((error: AxiosError) => {
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message: error.message,
    });
  }, []);

  const mutation = useMutation([], fn, { onError });

  return { ...mutation };
};
