import { memo, useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import { Landing } from '@/features/misc';
import { useUserByEmailLazyQuery, useUserLazyQuery } from '@/generated/graphql';
import { useFirebase } from '@/lib/firebase';
import { useUserStore } from '@/stores/user';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useGetUserByEmal } from './useGetUserByEmail';

export const AppRoutesComponent = () => {
  const [userQuery] = useUserLazyQuery();
  // const {data} = useQuery([])
  const { auth, db, updateDocument } = useFirebase();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  // const { validateAuth } = useCognito();
  const { user: userStore, setUser, setOrganization } = useUserStore();
  const [userByEmail] = useUserByEmailLazyQuery();
  const { data, isLoading } = useGetUserByEmal({
    email: localStorage.getItem('ConfecTronic-User'),
  });

  useEffect(() => {
    const token = localStorage.getItem('ConfecTronic-Token');
    const emailUser = localStorage.getItem('ConfecTronic-User');
    const isValid = async () => {
      try {
        try {
          if (
            typeof token === 'undefined' ||
            Boolean(token) === false ||
            token === null ||
            typeof emailUser === 'undefined' ||
            Boolean(emailUser) === false ||
            emailUser === null
          ) {
            throw new Error('No hay usuario');
          }

          const userId = data?.data.id;
          const name = `${data?.data.firstname} ${data?.data.lastname}`;
          const role = data?.data.role;
          const email = data?.data.email;
          const organizationId = data?.data.organization?.id;

          if (data?.data) {
            const res = data?.data;

            if (typeof res.organization?.id === 'undefined') {
              // TODO: Handle onboarding
              setUser({
                name,
                role,
                id: userId,
                uid: userId,
                email,
              } as any);
              setLoaded(true);
              return navigate('/app/onboarding');
            }

            setUser({
              name,
              role,
              id: userId,
              uid: userId,
              email,
              organizationId: res.organization.id,
              organization: organizationId,
              activationCode: res.activationCode,
            } as any);
            setOrganization(res.organization);
            setLoaded(true);

            return navigate(window.location.pathname);
          }

          setUser({
            id: userId,
            email: emailUser,
          } as any);
          setLoaded(true);
          return navigate('/app/onboarding');
        } catch (err) {
          console.error(err);
        }
      } catch (err) {
        navigate('/');
      }
    };

    if (!loaded && token && emailUser && !isLoading && data) {
      isValid();
    }
  }, [loaded, isLoading, data]);

  const commonRoutes = [{ path: '/', element: <Landing /> }];

  const routes = userStore ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  // if (!userStore) return null;

  // console.log('element', element);
  // if (element === null) {
  //   navigate('/');
  // }

  return <>{element}</>;
};

export const AppRoutes = memo(AppRoutesComponent);
