import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { useDeleteOperatorMutation } from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
  name: z.string().min(1, 'Required'),
});

type DeleteOperatorrModalProps = {
  isOpen: boolean;
  id: any;
  closeModal: () => void;
  onSuccess: () => void;
};

type OperatorValues = {
  name: string;
};

export const DeleteOperatorrModal: React.FC<DeleteOperatorrModalProps> = ({
  isOpen = false,
  id,
  closeModal,
  onSuccess,
}) => {
  const [deleteOperator] = useDeleteOperatorMutation();
  const [loading, setLoading] = useState(false);
  const handleUpdateOperator = async () => {
    setLoading(true);
    try {
      const deletedOperator = await deleteOperator({
        variables: {
          operatorId: id.id,
        },
      });
      if (deletedOperator) {
        onSuccess();
        closeModal();
        setLoading(false);
        useNotificationStore.getState().addNotification({
          type: 'success',
          title: 'Operador eliminado',
          message: '',
        });
      }
    } catch (err) {
      console.error('error deleting operator: ' + err);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (err as Error).message,
      });
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="mr-4 text-center text-lg font-medium leading-6 text-gray-900">
                  Eliminar operador
                </Dialog.Title>
                <div className="my-4">¿Deseas borrar al operador?</div>
                <div className="mt-2 flex">
                  <Button
                    isLoading={loading}
                    onClick={() => closeModal()}
                    className="mr-2 inline-flex w-full justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                    Cancelar
                  </Button>
                  <Button
                    isLoading={loading}
                    onClick={handleUpdateOperator}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                    Eliminar
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
