import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import { useNotificationStore } from '@/stores/notifications';
import { useUserStore } from '@/stores/user';

import { ROLES } from './authorization';

const firebaseConfig = {
  apiKey: 'AIzaSyC6hYI1r5-igZ0FRZrmJT8lg5sTRFNqG20',
  authDomain: 'qualitime-420.firebaseapp.com',
  projectId: 'qualitime-420',
  storageBucket: 'qualitime-420.appspot.com',
  messagingSenderId: '873910749271',
  appId: '1:873910749271:web:63d07f93f91e549a5e3b12',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const useFirebase = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const { user, setUser } = useUserStore();

  const setNotification = (
    type: 'info' | 'warning' | 'success' | 'error',
    title: string,
    message: string,
  ) => {
    addNotification({
      type,
      title,
      message,
    });
  };
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      const session = await signInWithEmailAndPassword(auth, email, password);
      return session;
    } catch (err) {
      console.error(err);

      const message = (err as Error).message.includes('auth/user-not-found')
        ? 'Usuario o contraseña inválidos'
        : 'No se pudo iniciar sesión';
      setNotification('error', 'Error', message);
      return false;
    }
  };

  const registerWithEmailAndPassword = async (values, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      const documentAdded = await addDoc(collection(db, 'users'), {
        uid: user.uid,
        ...values,
        role: ROLES.ADMIN,
        authProvider: 'local',
        email,
      });
      return documentAdded;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
    }
  };

  const addDocument = async (table: string, data) => {
    console.log('CREATE: ', { table, data });
    try {
      const documentAdded = await addDoc(collection(db, table), {
        ...data,
      });
      return documentAdded;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const removeDocument = async (table: string, field, id) => {
    console.log('DELETE: ', { table, field, id });
    try {
      const q = query(collection(db, table), where(field, '==', id));
      const docs = await getDocs(q);

      if (docs.docs.length === 0) return false;

      let docID = '';
      docs.forEach((doc) => {
        // if email is you primary key then only document will be fetched so it is safe to continue, this line will get the documentID of user so that we can update it
        docID = doc.id;
      });
      const document = doc(db, table, docID);
      await deleteDoc(document as any);
      return true;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const updateDocument = async (table: string, id: string, data) => {
    console.log('PUT: ', { table, id, data });
    const getId = (table) => {
      if (table === 'users') {
        return 'uid';
      }
      if (table === 'history') {
        return 'operationId';
      }
      if (table === 'breaks') {
        return 'moduleId';
      }
      return 'id';
    };
    const q = query(collection(db, table), where(getId(table), '==', id));
    const docs = await getDocs(q);

    let docID = '';
    docs.forEach((doc) => {
      // if email is you primary key then only document will be fetched so it is safe to continue, this line will get the documentID of user so that we can update it
      docID = doc.id;
    });
    const document = doc(db, table, docID);

    try {
      await updateDoc(document, {
        ...data,
      });
      return true;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const getSingleDocument = async (
    table: string,
    field: string,
    id: string,
  ) => {
    console.log('GET BY ID: ', { table, field, id });
    try {
      const q = query(collection(db, table), where(field, '==', id));
      const doc = await getDocs(q);

      if (doc.docs.length === 0) return false;
      const data = doc.docs[0].data();

      return data;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const getDocuments = async (table: string, field: string, id: string) => {
    console.log('GET: ', { table, field, id });
    try {
      const qM = query(collection(db, table), where(field, '==', id));
      const documents = await getDocs(qM);
      const data = documents.docs.map((doc) => doc.data());
      return data;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const getCachedDocuments = async (
    table: string,
    field: string,
    id: string,
  ) => {
    console.log('GET CACHED: ', { table, field, id });
    try {
      const qM = query(collection(db, table), where(field, '==', id));
      const data: any = [];
      onSnapshot(qM, { includeMetadataChanges: true }, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // if (change.type === "added") {
          //   console.log("New city: ", change.doc.data());
          // }

          // const source = snapshot.metadata.fromCache ? 'local cache' : 'server';

          data.push(change.doc.data());
        });
      });

      // const documents = await getDocs(qM);
      // const data = documents.docs.map((doc: { data: () => any }) => doc.data());
      // return data;
      return data;
    } catch (err) {
      console.error(err);
      setNotification('error', 'Error', (err as Error).message);
      return false;
    }
  };

  const logout = async () => {
    signOut(auth);
    setUser(undefined as any);
    navigate('/auth/login');
  };

  return {
    query,
    auth,
    db,
    getDocs,
    where,
    collection,
    addDocument,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    updateDocument,
    getSingleDocument,
    getDocuments,
    removeDocument,
    getCachedDocuments,
  };
};
