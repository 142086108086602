import { useRef } from 'react';
import { Controller, UseFormRegisterReturn } from 'react-hook-form';
import Select from 'react-select';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type AutoCompleteFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  value?: any;
  options?: Array<any>;
  control: any;
};

export const AutoCompleteField = (props: AutoCompleteFieldProps) => {
  const { control, label, options, className, registration, error, value } =
    props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (registration.ref) {
    registration.ref(inputRef.current);
  }

  return (
    <FieldWrapper label={label} error={error}>
      <>
        <Controller
          control={control}
          defaultValue={options?.find((c) => c.value === value)?.value}
          name={registration?.name as string}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select
                ref={ref}
                options={options}
                className={className}
                defaultValue={options?.filter((c) => c.value === value)}
                value={options?.filter((c) => c.value === value)}
                onChange={(val) => {
                  return onChange(val.value);
                }}
              />
            );
          }}
        />
      </>
    </FieldWrapper>
  );
};
