import * as React from 'react';

import { Head } from '../Head';

type AppLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const AppLayout = ({ children, title }: AppLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="mx-auto h-full w-full overflow-hidden">{children}</div>
    </>
  );
};
