import cn from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';

import { AppLayout } from '@/components/Layout';
import { Module, useModulesLazyQuery } from '@/generated/graphql';
import { useUserStore } from '@/stores/user';

import { DrawerPanel } from '../components';
import ModuleComponent from '../components/ModuleComponent';
import { useGetModulesByOrganization } from '../hooks/useGetModulesByOrganization';

const DashboardComponent = ({ isPreview = false }) => {
  const navigate = useNavigate();
  const [open, toggle] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [queryModules] = useModulesLazyQuery();
  const { user, organization } = useUserStore();
  const { data, isLoading, refetch } = useGetModulesByOrganization({
    organization: user?.organizationId,
  });

  const [loaded, setLoaded] = useState(false);

  const modulesRef = useRef<Module[] | null>();

  useEffect(() => {
    if (!isLoading && data?.data) {
      setModules(data.data);
      modulesRef.current = data.data;
      setLoaded(true);
    }
  }, [data, isLoading]);

  const onCreateModuleSuccess = async () => {
    refetch();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <AppLayout title="Fluenta">
        {!isPreview && (
          <div className="absolute bottom-0 z-10 w-full bg-white">
            <DrawerPanel
              open={open}
              toggle={toggle}
              // TODO: change this values
              totalPhones={50}
              totalUsers={50}
            />
          </div>
        )}

        <div className="h-full overflow-y-scroll">
          <div className={cn(!open ? 'pb-[58px]' : 'pb-[498px]')}>
            {modules.map((module, index) => {
              return (
                <ModuleComponent
                  key={index}
                  moduleIndex={index}
                  name={((module?.position as number) + 1).toString()}
                  isActive={typeof module !== 'undefined'}
                  id={module.id}
                  totalOperations={module.totalOperations}
                  // TODO: change this values
                  organization={user?.organizationId as string}
                  timezone={organization?.timezone as string}
                  onCreateModuleSuccess={onCreateModuleSuccess}
                  isPreview={isPreview}
                />
              );
            })}
          </div>
        </div>
      </AppLayout>
    </DndProvider>
  );
};

export const Dashboard = memo(DashboardComponent);
