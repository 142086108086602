import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useUpdateOperationMutation } from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
  total: z.string().min(1, 'Required'),
});

type AccumulateModalProps = {
  isOpen: boolean;
  id: string;
  total: string;
  completed: string;
  closeModal: () => void;
  onSuccess: () => void;
};

type OperationValues = {
  total: string;
};

export const AccumulateModal: React.FC<AccumulateModalProps> = ({
  isOpen = false,
  id,
  total = '0',
  completed,
  closeModal,
  onSuccess,
}) => {
  const [updateOperation] = useUpdateOperationMutation();

  const [loading, setLoading] = useState(false);

  const handleEditOperation = async (values: OperationValues) => {
    setLoading(true);
    try {
      if (typeof values.total !== 'undefined' && values.total.length > 0) {
        const updatedOperation = await updateOperation({
          variables: {
            data: {
              id,
              remaining: parseInt(total, 10) - parseInt(values.total, 10),
              totalCompleted: parseInt(values.total, 10),
            },
          },
        });
        if (updatedOperation) {
          onSuccess();
          closeModal();
          setLoading(false);
        }
      } else {
        useNotificationStore.getState().addNotification({
          type: 'error',
          title: 'Error',
          message: 'Debe de seleccionar el total.',
        });
      }
    } catch (err) {
      console.error('error changing accumulate : ', err);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: 'Hubo un error al editar la operación',
      });
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Cambiar acumulado
                </Dialog.Title>
                <div className="mt-2">
                  <Form<OperationValues, typeof schema>
                    onSubmit={async (values) => {
                      handleEditOperation(values);
                    }}
                    schema={schema}>
                    {({ register, formState }) => {
                      return (
                        <>
                          <InputField
                            type="text"
                            label="Acumulado"
                            error={formState.errors['total']}
                            registration={register('total')}
                            value={completed}
                          />
                          <div>
                            <Button
                              isLoading={loading}
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                              Guardar
                            </Button>
                          </div>
                        </>
                      );
                    }}
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
