import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import {
  useCreateModuleMutation,
  useCreateOrganizationMutation,
  useCreateUserMutation,
} from '@/generated/graphql';

import { useCreateOrganization } from '../hooks/useCreateOrganization';
import { useCreateUser } from '../hooks/useCreateUser';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  totalModules: z.string().min(1, 'Required'),
  companyName: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
});

type NewUserModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess: () => void;
};

export const NewUserModal: React.FC<NewUserModalProps> = ({
  isOpen = false,
  closeModal,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [createUser] = useCreateUserMutation();
  const [createOrganization] = useCreateOrganizationMutation();
  const [createModule] = useCreateModuleMutation();

  const { mutateAsync: createUserPost } = useCreateUser();
  const { mutateAsync: createOrganizationPost } = useCreateOrganization();

  const handleSubmit = async (values) => {
    console.log({ values });
    setLoading(true);
    try {
      const nameSplit = values.name.split(' ');

      const createdOrganization = await createOrganizationPost({
        name: values.companyName,
        timezone: 'America/Bogota',
      });

      console.log({ createdOrganization });
      if (createdOrganization.data) {
        const createdUser = await createUserPost({
          firstName: nameSplit[0],
          lastName: nameSplit[1],
          // organizationId: createdOrganization.data?.createOrganization
          //   .id as string,
          organizationId: createdOrganization.data.idx,
          email: values.email,
          activationCode: Math.floor(100000 + Math.random() * 900000),
        });

        console.log({ createdUser, createdOrganization });
      }

      // const createdOrganization = await createOrganization({
      //   variables: {
      //     data: {
      //       name: values.companyName,
      //       timezone: 'America/Bogota',
      //     },
      //   },
      // });

      // if (createdOrganization) {
      //   const nameSplit = values.name.split(' ');
      //   const createdUser = await createUserPost({
      //     firstName: nameSplit[0],
      //     lastName: nameSplit[1],
      //     // organizationId: createdOrganization.data?.createOrganization
      //     //   .id as string,
      //     email: values.email,
      //     activationCode: Math.floor(100000 + Math.random() * 900000),
      //   });

      //   console.log({ createdUser });

      //   const totalModules = Array.from(
      //     { length: values.totalModules },
      //     (_, idx) => +idx,
      //   );

      //   for (const module of totalModules) {
      //     await createModule({
      //       variables: {
      //         data: {
      //           position: module,
      //           organizationId: createdOrganization.data?.createOrganization
      //             .id as string,
      //           totalOperations: 0,
      //         },
      //       },
      //     });
      //   }
      //   useNotificationStore.getState().addNotification({
      //     title: 'Éxito',
      //     type: 'success',
      //     message: 'Usuario creado',
      //   });
      //   setLoading(false);
      //   closeModal();
      //   onSuccess();
      // }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Crear Usuario
                </Dialog.Title>
                <div className="mt-2">
                  <Form<any, typeof schema>
                    onSubmit={async (values) => {
                      handleSubmit(values);
                    }}
                    schema={schema}>
                    {({ register, formState }) => (
                      <>
                        <InputField
                          type="text"
                          label="Nombre y apellido"
                          error={formState.errors['name'] as any}
                          registration={register('name')}
                        />
                        <InputField
                          type="number"
                          label="Cuántos módulos"
                          error={formState.errors['totalModules'] as any}
                          registration={register('totalModules')}
                        />
                        <InputField
                          type="text"
                          label="Nombre de la empresa"
                          error={formState.errors['companyName'] as any}
                          registration={register('companyName')}
                        />
                        <InputField
                          type="email"
                          label="Correo electrónico"
                          error={formState.errors['email'] as any}
                          registration={register('email')}
                        />
                        <div>
                          <Button
                            isLoading={loading}
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                            Crear
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
