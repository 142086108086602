import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type ActivationCodeInput = {
  activationCode: Scalars['Int'];
};

/** Operation productivity color */
export enum Color {
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export type CreateHistoryInput = {
  day: Scalars['String'];
  goal: Scalars['Int'];
  hour: Scalars['Int'];
  operationId: Scalars['String'];
  total: Scalars['Int'];
};

export type CreateModuleInput = {
  goal?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  totalOperations: Scalars['Int'];
};

export type CreateOperationInput = {
  color: Scalars['String'];
  completed: Scalars['Int'];
  currentHour: Scalars['Int'];
  goal: Scalars['Int'];
  goalReference: Scalars['Int'];
  isReference: Scalars['Boolean'];
  moduleId: Scalars['String'];
  name: Scalars['String'];
  onBreak: Scalars['Boolean'];
  operatorActive: Scalars['Boolean'];
  position: Scalars['Int'];
  remaining: Scalars['Int'];
  startedLate: Scalars['Boolean'];
  total: Scalars['Int'];
  totalCompleted: Scalars['Int'];
};

export type CreateOperatorInput = {
  activationCode: Scalars['Int'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  operationId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  timezone: Scalars['String'];
};

export type CreateRecordCompletedInput = {
  color: Scalars['String'];
  completed: Scalars['Int'];
  day: Scalars['String'];
  goal: Scalars['Int'];
  hour: Scalars['Int'];
  operationId: Scalars['String'];
  remaining: Scalars['Int'];
  total: Scalars['Int'];
  totalCompleted: Scalars['Int'];
};

export type CreateRestTimeInput = {
  endHour: Scalars['Int'];
  endMin: Scalars['Int'];
  moduleId: Scalars['String'];
  startHour: Scalars['Int'];
  startMin: Scalars['Int'];
};

export type CreateUserInput = {
  activationCode: Scalars['Int'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  organizationId: Scalars['String'];
};

export type History = {
  __typename?: 'History';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  day: Scalars['String'];
  goal: Scalars['Int'];
  hour: Scalars['Int'];
  id: Scalars['ID'];
  operation?: Maybe<Operation>;
  total: Scalars['Int'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type Module = {
  __typename?: 'Module';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  goal?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  operations?: Maybe<Array<Operation>>;
  organization?: Maybe<Organization>;
  position: Scalars['Int'];
  totalOperations: Scalars['Int'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createHistory: History;
  createModule: Module;
  createOperation: Operation;
  createOperator: Operator;
  createOrganization: Organization;
  createRestTime: RestTime;
  createUser: User;
  deleteOperator: Operator;
  deleteRestTime: RestTime;
  login: User;
  logout: User;
  registerEvent: Operation;
  removeOperationAsReference: Operation;
  removeOperationOperator: Operation;
  removeOperatorOperation: Operator;
  setOperationAsReference: Operation;
  signup: User;
  updateModule: Module;
  updateOperation: Operation;
  updateOperations: Array<Operation>;
  updateOperationsReference: Array<Operation>;
  updateOperator: Operator;
  updateRestTime: RestTime;
  updateUser: User;
};

export type MutationCreateHistoryArgs = {
  data: CreateHistoryInput;
};

export type MutationCreateModuleArgs = {
  data: CreateModuleInput;
};

export type MutationCreateOperationArgs = {
  data: CreateOperationInput;
};

export type MutationCreateOperatorArgs = {
  data: CreateOperatorInput;
};

export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};

export type MutationCreateRestTimeArgs = {
  data: CreateRestTimeInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationDeleteOperatorArgs = {
  operatorId: Scalars['String'];
};

export type MutationDeleteRestTimeArgs = {
  restTimeId: Scalars['String'];
};

export type MutationLoginArgs = {
  data: ActivationCodeInput;
};

export type MutationRegisterEventArgs = {
  data: CreateRecordCompletedInput;
};

export type MutationRemoveOperationAsReferenceArgs = {
  operationId: Scalars['String'];
};

export type MutationRemoveOperationOperatorArgs = {
  operationId: Scalars['String'];
};

export type MutationRemoveOperatorOperationArgs = {
  operationId: Scalars['String'];
};

export type MutationSetOperationAsReferenceArgs = {
  operationId: Scalars['String'];
};

export type MutationSignupArgs = {
  data: SignupInput;
};

export type MutationUpdateModuleArgs = {
  data: UpdateModuleInput;
};

export type MutationUpdateOperationArgs = {
  data: UpdateOperationInput;
};

export type MutationUpdateOperationsArgs = {
  data: UpdateOperationsInput;
};

export type MutationUpdateOperationsReferenceArgs = {
  goal: Scalars['Int'];
  moduleId: Scalars['String'];
};

export type MutationUpdateOperatorArgs = {
  data: UpdateOperatorInput;
};

export type MutationUpdateRestTimeArgs = {
  data: UpdateRestTimeInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type Operation = {
  __typename?: 'Operation';
  color: Color;
  completed: Scalars['Int'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  currentHour: Scalars['Int'];
  goal: Scalars['Int'];
  goalReference: Scalars['Int'];
  id: Scalars['ID'];
  isReference: Scalars['Boolean'];
  module?: Maybe<Module>;
  moduleId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  onBreak: Scalars['Boolean'];
  operator?: Maybe<Operator>;
  operatorActive: Scalars['Boolean'];
  position: Scalars['Int'];
  remaining: Scalars['Int'];
  startedLate: Scalars['Boolean'];
  total: Scalars['Int'];
  totalCompleted: Scalars['Int'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type Operator = {
  __typename?: 'Operator';
  activationCode: Scalars['Int'];
  active: Scalars['Boolean'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type Organization = {
  __typename?: 'Organization';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  modules?: Maybe<Array<Module>>;
  name: Scalars['String'];
  operators?: Maybe<Array<Operator>>;
  timezone: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
  idx: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  findOperatorOperation: Operator;
  getUsers: Array<User>;
  hello: Scalars['String'];
  helloWorld: Scalars['String'];
  historyOperation: Array<History>;
  me: User;
  module: Module;
  modules: Array<Module>;
  operation: Operation;
  operations: Array<Operation>;
  operator: Operator;
  operatorByCode: Operator;
  operators: Array<Operator>;
  organization: Organization;
  restTimeExists: RestTime;
  restTimes: Array<RestTime>;
  user: User;
  userByEmail: User;
};

export type QueryFindOperatorOperationArgs = {
  operationId: Scalars['String'];
};

export type QueryHelloArgs = {
  name: Scalars['String'];
};

export type QueryHistoryOperationArgs = {
  day: Scalars['String'];
  operationId: Scalars['String'];
};

export type QueryModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryModulesArgs = {
  organizationId: Scalars['String'];
};

export type QueryOperationArgs = {
  operationId: Scalars['String'];
};

export type QueryOperationsArgs = {
  moduleId: Scalars['String'];
};

export type QueryOperatorArgs = {
  operatorId: Scalars['String'];
};

export type QueryOperatorByCodeArgs = {
  activationCode: Scalars['Int'];
};

export type QueryOperatorsArgs = {
  organizationId: Scalars['String'];
};

export type QueryOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type QueryRestTimeExistsArgs = {
  restTimeId: Scalars['String'];
};

export type QueryRestTimesArgs = {
  moduleId: Scalars['String'];
};

export type QueryUserArgs = {
  userId: Scalars['String'];
};

export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};

export type RestTime = {
  __typename?: 'RestTime';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  endHour: Scalars['Int'];
  endMin: Scalars['Int'];
  id: Scalars['ID'];
  module?: Maybe<Module>;
  moduleId?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMin: Scalars['Int'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** User role */
export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER',
}

export type Session = {
  __typename?: 'Session';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  token: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type SignupInput = {
  activationCode?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
};

export type Subscription = {
  __typename?: 'Subscription';
  operationUpdated: Operation;
  operationsUpdated: Array<Operation>;
  operatorsUpdated: Array<Operator>;
  registeredEvent: Operation;
  restTimeUpdated: Array<RestTime>;
  updateHour: Operation;
};

export type SubscriptionOperationUpdatedArgs = {
  operationId: Scalars['String'];
};

export type SubscriptionOperationsUpdatedArgs = {
  moduleId: Scalars['String'];
};

export type SubscriptionOperatorsUpdatedArgs = {
  organizationId: Scalars['String'];
};

export type SubscriptionRegisteredEventArgs = {
  operationId: Scalars['String'];
};

export type SubscriptionRestTimeUpdatedArgs = {
  moduleId: Scalars['String'];
};

export type SubscriptionUpdateHourArgs = {
  operationId: Scalars['String'];
};

export type UpdateModuleInput = {
  goal?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  totalOperations?: InputMaybe<Scalars['Int']>;
};

export type UpdateOperationInput = {
  color?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Int']>;
  currentHour?: InputMaybe<Scalars['Int']>;
  goal?: InputMaybe<Scalars['Int']>;
  goalReference?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  isReference?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  onBreak?: InputMaybe<Scalars['Boolean']>;
  operatorActive?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  remaining?: InputMaybe<Scalars['Int']>;
  startedLate?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Int']>;
  totalCompleted?: InputMaybe<Scalars['Int']>;
};

export type UpdateOperationsInput = {
  color?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Int']>;
  currentHour?: InputMaybe<Scalars['Int']>;
  goal?: InputMaybe<Scalars['Int']>;
  goalReference?: InputMaybe<Scalars['Int']>;
  isReference?: InputMaybe<Scalars['Boolean']>;
  moduleId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  operatorActive?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  remaining?: InputMaybe<Scalars['Int']>;
  startedLate?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Int']>;
};

export type UpdateOperatorInput = {
  activationCode?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  operationId?: InputMaybe<Scalars['String']>;
};

export type UpdateRestTimeInput = {
  endHour: Scalars['Int'];
  endMin: Scalars['Int'];
  restTimeId: Scalars['String'];
  startHour: Scalars['Int'];
  startMin: Scalars['Int'];
};

export type UpdateUserInput = {
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  activationCode?: Maybe<Scalars['Int']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  role: Role;
  sessions?: Maybe<Array<Session>>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type LoginMutationVariables = Exact<{
  data: ActivationCodeInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename: 'User';
    email: string;
    firstname?: string | null;
    id: string;
    lastname?: string | null;
    role: Role;
    sessions?: Array<{
      __typename?: 'Session';
      id: string;
      token: string;
    }> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      timezone: string;
    } | null;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    firstname?: string | null;
    lastname?: string | null;
    role: Role;
    email: string;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      timezone: string;
    } | null;
  };
};

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type UserByEmailQuery = {
  __typename?: 'Query';
  userByEmail: {
    __typename?: 'User';
    id: string;
    firstname?: string | null;
    lastname?: string | null;
    role: Role;
    email: string;
    activationCode?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      timezone: string;
    } | null;
  };
};

export type SignupMutationVariables = Exact<{
  data: SignupInput;
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'User';
    createdAt: any;
    email: string;
    firstname?: string | null;
    id: string;
    lastname?: string | null;
    role: Role;
    updatedAt: any;
  };
};

export type OperationDetailsFragment = {
  __typename?: 'Operation';
  id: string;
  currentHour: number;
  goal: number;
  goalReference: number;
  isReference: boolean;
  name: string;
  color: Color;
  operatorActive: boolean;
  remaining: number;
  startedLate: boolean;
  total: number;
  completed: number;
  totalCompleted: number;
  onBreak: boolean;
  position: number;
  operator?: {
    __typename?: 'Operator';
    id: string;
    name: string;
    active: boolean;
  } | null;
};

export type CreateModuleMutationVariables = Exact<{
  data: CreateModuleInput;
}>;

export type CreateModuleMutation = {
  __typename?: 'Mutation';
  createModule: {
    __typename?: 'Module';
    id: string;
    position: number;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
  };
};

export type CreateOperationMutationVariables = Exact<{
  data: CreateOperationInput;
}>;

export type CreateOperationMutation = {
  __typename?: 'Mutation';
  createOperation: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type OperationQueryVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type OperationQuery = {
  __typename?: 'Query';
  operation: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type OperationsQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type OperationsQuery = {
  __typename?: 'Query';
  operations: Array<{
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  }>;
};

export type ModulesQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type ModulesQuery = {
  __typename?: 'Query';
  modules: Array<{
    __typename: 'Module';
    createdAt: any;
    updatedAt: any;
    id: string;
    position: number;
    name?: string | null;
    totalOperations: number;
  }>;
};

export type ModuleQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type ModuleQuery = {
  __typename?: 'Query';
  module: {
    __typename: 'Module';
    createdAt: any;
    updatedAt: any;
    id: string;
    position: number;
    totalOperations: number;
    goal?: number | null;
    name?: string | null;
    operations?: Array<{
      __typename: 'Operation';
      id: string;
      currentHour: number;
      goal: number;
      goalReference: number;
      isReference: boolean;
      name: string;
      color: Color;
      operatorActive: boolean;
      remaining: number;
      startedLate: boolean;
      total: number;
      completed: number;
      totalCompleted: number;
      onBreak: boolean;
      position: number;
      operator?: {
        __typename?: 'Operator';
        id: string;
        name: string;
        active: boolean;
      } | null;
    }> | null;
  };
};

export type UpdateModuleMutationVariables = Exact<{
  data: UpdateModuleInput;
}>;

export type UpdateModuleMutation = {
  __typename?: 'Mutation';
  updateModule: {
    __typename: 'Module';
    createdAt: any;
    updatedAt: any;
    id: string;
    position: number;
    totalOperations: number;
    goal?: number | null;
    name?: string | null;
  };
};

export type RestTimeQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type RestTimeQuery = {
  __typename?: 'Query';
  restTimes: Array<{
    __typename?: 'RestTime';
    id: string;
    startHour: number;
    startMin: number;
    endHour: number;
    endMin: number;
  }>;
};

export type CreateRestTimeMutationVariables = Exact<{
  data: CreateRestTimeInput;
}>;

export type CreateRestTimeMutation = {
  __typename?: 'Mutation';
  createRestTime: {
    __typename: 'RestTime';
    createdAt: any;
    endHour: number;
    endMin: number;
    id: string;
    startHour: number;
    startMin: number;
    updatedAt: any;
  };
};

export type UpdateRestTimeMutationVariables = Exact<{
  data: UpdateRestTimeInput;
}>;

export type UpdateRestTimeMutation = {
  __typename?: 'Mutation';
  updateRestTime: {
    __typename: 'RestTime';
    createdAt: any;
    endHour: number;
    endMin: number;
    id: string;
    startHour: number;
    startMin: number;
    updatedAt: any;
  };
};

export type DeleteRestTimeMutationVariables = Exact<{
  restTimeId: Scalars['String'];
}>;

export type DeleteRestTimeMutation = {
  __typename?: 'Mutation';
  deleteRestTime: {
    __typename: 'RestTime';
    createdAt: any;
    endHour: number;
    endMin: number;
    id: string;
    startHour: number;
    startMin: number;
    updatedAt: any;
  };
};

export type RestTimeExistsQueryVariables = Exact<{
  restTimeId: Scalars['String'];
}>;

export type RestTimeExistsQuery = {
  __typename?: 'Query';
  restTimeExists: {
    __typename: 'RestTime';
    createdAt: any;
    endHour: number;
    endMin: number;
    id: string;
    startHour: number;
    startMin: number;
    updatedAt: any;
  };
};

export type UpdateOperationMutationVariables = Exact<{
  data: UpdateOperationInput;
}>;

export type UpdateOperationMutation = {
  __typename?: 'Mutation';
  updateOperation: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type UpdateOperationsMutationVariables = Exact<{
  data: UpdateOperationsInput;
}>;

export type UpdateOperationsMutation = {
  __typename?: 'Mutation';
  updateOperations: Array<{
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  }>;
};

export type SetOperationAsReferenceMutationVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type SetOperationAsReferenceMutation = {
  __typename?: 'Mutation';
  setOperationAsReference: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type RemoveOperationAsReferenceMutationVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type RemoveOperationAsReferenceMutation = {
  __typename?: 'Mutation';
  removeOperationAsReference: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type RemoveOperationOperatorMutationVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type RemoveOperationOperatorMutation = {
  __typename?: 'Mutation';
  removeOperationOperator: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type RemoveOperatorOperationMutationVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type RemoveOperatorOperationMutation = {
  __typename?: 'Mutation';
  removeOperatorOperation: {
    __typename: 'Operator';
    activationCode: number;
    active: boolean;
    createdAt: any;
    id: string;
    name: string;
    updatedAt: any;
  };
};

export type FindOperatorOperationQueryVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type FindOperatorOperationQuery = {
  __typename?: 'Query';
  findOperatorOperation: {
    __typename: 'Operator';
    activationCode: number;
    active: boolean;
    createdAt: any;
    id: string;
    name: string;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
    } | null;
  };
};

export type UpdateOperatorMutationVariables = Exact<{
  data: UpdateOperatorInput;
}>;

export type UpdateOperatorMutation = {
  __typename?: 'Mutation';
  updateOperator: {
    __typename: 'Operator';
    activationCode: number;
    active: boolean;
    createdAt: any;
    id: string;
    name: string;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
      name: string;
    } | null;
  };
};

export type OperatorQueryVariables = Exact<{
  operatorId: Scalars['String'];
}>;

export type OperatorQuery = {
  __typename?: 'Query';
  operator: {
    __typename: 'Operator';
    activationCode: number;
    active: boolean;
    createdAt: any;
    id: string;
    name: string;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
      name: string;
    } | null;
  };
};

export type CreateOperatorMutationVariables = Exact<{
  data: CreateOperatorInput;
}>;

export type CreateOperatorMutation = {
  __typename?: 'Mutation';
  createOperator: { __typename?: 'Operator'; id: string };
};

export type DeleteOperatorMutationVariables = Exact<{
  operatorId: Scalars['String'];
}>;

export type DeleteOperatorMutation = {
  __typename?: 'Mutation';
  deleteOperator: { __typename?: 'Operator'; id: string };
};

export type OperatorsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OperatorsQuery = {
  __typename?: 'Query';
  operators: Array<{
    __typename?: 'Operator';
    id: string;
    activationCode: number;
    active: boolean;
    createdAt: any;
    name: string;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
      name: string;
    } | null;
  }>;
};

export type OperatorByCodeQueryVariables = Exact<{
  activationCode: Scalars['Int'];
}>;

export type OperatorByCodeQuery = {
  __typename?: 'Query';
  operatorByCode: {
    __typename?: 'Operator';
    id: string;
    activationCode: number;
    active: boolean;
    createdAt: any;
    name: string;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
      module?: {
        __typename?: 'Module';
        name?: string | null;
        id: string;
        goal?: number | null;
      } | null;
    } | null;
  };
};

export type CreateHistoryMutationVariables = Exact<{
  data: CreateHistoryInput;
}>;

export type CreateHistoryMutation = {
  __typename?: 'Mutation';
  createHistory: {
    __typename: 'History';
    createdAt: any;
    day: string;
    goal: number;
    hour: number;
    id: string;
    total: number;
    updatedAt: any;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
    } | null;
  };
};

export type OperationUpdatedSubscriptionVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type OperationUpdatedSubscription = {
  __typename?: 'Subscription';
  operationUpdated: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type RestTimesUpdatedSubscriptionVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type RestTimesUpdatedSubscription = {
  __typename?: 'Subscription';
  restTimeUpdated: Array<{
    __typename: 'RestTime';
    id: string;
    startHour: number;
    startMin: number;
    endHour: number;
    endMin: number;
    module?: {
      __typename?: 'Module';
      name?: string | null;
      id: string;
      goal?: number | null;
    } | null;
  }>;
};

export type HistoryOperationQueryVariables = Exact<{
  day: Scalars['String'];
  operationId: Scalars['String'];
}>;

export type HistoryOperationQuery = {
  __typename?: 'Query';
  historyOperation: Array<{
    __typename: 'History';
    id: string;
    day: string;
    hour: number;
    goal: number;
    total: number;
  }>;
};

export type OperationsUpdatedSubscriptionVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type OperationsUpdatedSubscription = {
  __typename?: 'Subscription';
  operationsUpdated: Array<{
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  }>;
};

export type OperatorsUpdatedSubscriptionVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OperatorsUpdatedSubscription = {
  __typename?: 'Subscription';
  operatorsUpdated: Array<{
    __typename: 'Operator';
    id: string;
    activationCode: number;
    active: boolean;
    name: string;
    operation?: {
      __typename?: 'Operation';
      position: number;
      id: string;
      name: string;
    } | null;
    organization?: { __typename?: 'Organization'; id: string } | null;
  }>;
};

export type RegisterEventMutationVariables = Exact<{
  data: CreateRecordCompletedInput;
}>;

export type RegisterEventMutation = {
  __typename?: 'Mutation';
  registerEvent: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type RegisteredEventSubscriptionVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type RegisteredEventSubscription = {
  __typename?: 'Subscription';
  registeredEvent: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename: 'User';
    createdAt: any;
    email: string;
    firstname?: string | null;
    id: string;
    lastname?: string | null;
    role: Role;
    updatedAt: any;
  };
};

export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename: 'Organization';
    createdAt: any;
    id: string;
    name: string;
    timezone: string;
    updatedAt: any;
  };
};

export type UpdateHourSubscriptionVariables = Exact<{
  operationId: Scalars['String'];
}>;

export type UpdateHourSubscription = {
  __typename?: 'Subscription';
  updateHour: {
    __typename: 'Operation';
    id: string;
    currentHour: number;
    goal: number;
    goalReference: number;
    isReference: boolean;
    name: string;
    color: Color;
    operatorActive: boolean;
    remaining: number;
    startedLate: boolean;
    total: number;
    completed: number;
    totalCompleted: number;
    onBreak: boolean;
    position: number;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      active: boolean;
    } | null;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: Array<{
    __typename: 'User';
    activationCode?: number | null;
    createdAt: any;
    email: string;
    firstname?: string | null;
    id: string;
    lastname?: string | null;
    role: Role;
    updatedAt: any;
    organization?: {
      __typename?: 'Organization';
      createdAt: any;
      id: string;
      name: string;
      timezone: string;
      updatedAt: any;
    } | null;
    sessions?: Array<{
      __typename?: 'Session';
      createdAt: any;
      id: string;
      token: string;
      updatedAt: any;
    }> | null;
  }>;
};

export const OperationDetailsFragmentDoc = gql`
  fragment OperationDetails on Operation {
    id
    currentHour
    goal
    goalReference
    isReference
    name
    color
    operator {
      id
      name
      active
    }
    operatorActive
    remaining
    startedLate
    total
    completed
    totalCompleted
    onBreak
    isReference
    position
  }
`;
export const LoginDocument = gql`
  mutation Login($data: ActivationCodeInput!) {
    login(data: $data) {
      __typename
      email
      firstname
      id
      lastname
      role
      sessions {
        id
        token
      }
      organization {
        id
        name
        timezone
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const UserDocument = gql`
  query User($userId: String!) {
    user(userId: $userId) {
      id
      firstname
      lastname
      role
      email
      organization {
        id
        name
        timezone
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserByEmailDocument = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      firstname
      lastname
      role
      email
      activationCode
      organization {
        id
        name
        timezone
      }
    }
  }
`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export function useUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<
  typeof useUserByEmailLazyQuery
>;
export type UserByEmailQueryResult = Apollo.QueryResult<
  UserByEmailQuery,
  UserByEmailQueryVariables
>;
export const SignupDocument = gql`
  mutation signup($data: SignupInput!) {
    signup(data: $data) {
      createdAt
      email
      firstname
      id
      lastname
      role
      updatedAt
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options,
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const CreateModuleDocument = gql`
  mutation createModule($data: CreateModuleInput!) {
    createModule(data: $data) {
      id
      position
      organization {
        id
        name
      }
    }
  }
`;
export type CreateModuleMutationFn = Apollo.MutationFunction<
  CreateModuleMutation,
  CreateModuleMutationVariables
>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateModuleMutation,
    CreateModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateModuleMutation,
    CreateModuleMutationVariables
  >(CreateModuleDocument, options);
}
export type CreateModuleMutationHookResult = ReturnType<
  typeof useCreateModuleMutation
>;
export type CreateModuleMutationResult =
  Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateModuleMutation,
  CreateModuleMutationVariables
>;
export const CreateOperationDocument = gql`
  mutation createOperation($data: CreateOperationInput!) {
    createOperation(data: $data) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type CreateOperationMutationFn = Apollo.MutationFunction<
  CreateOperationMutation,
  CreateOperationMutationVariables
>;

/**
 * __useCreateOperationMutation__
 *
 * To run a mutation, you first call `useCreateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationMutation, { data, loading, error }] = useCreateOperationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOperationMutation,
    CreateOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOperationMutation,
    CreateOperationMutationVariables
  >(CreateOperationDocument, options);
}
export type CreateOperationMutationHookResult = ReturnType<
  typeof useCreateOperationMutation
>;
export type CreateOperationMutationResult =
  Apollo.MutationResult<CreateOperationMutation>;
export type CreateOperationMutationOptions = Apollo.BaseMutationOptions<
  CreateOperationMutation,
  CreateOperationMutationVariables
>;
export const OperationDocument = gql`
  query Operation($operationId: String!) {
    operation(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useOperationQuery__
 *
 * To run a query within a React component, call `useOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationQuery({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useOperationQuery(
  baseOptions: Apollo.QueryHookOptions<OperationQuery, OperationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperationQuery, OperationQueryVariables>(
    OperationDocument,
    options,
  );
}
export function useOperationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperationQuery,
    OperationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperationQuery, OperationQueryVariables>(
    OperationDocument,
    options,
  );
}
export type OperationQueryHookResult = ReturnType<typeof useOperationQuery>;
export type OperationLazyQueryHookResult = ReturnType<
  typeof useOperationLazyQuery
>;
export type OperationQueryResult = Apollo.QueryResult<
  OperationQuery,
  OperationQueryVariables
>;
export const OperationsDocument = gql`
  query Operations($moduleId: String!) {
    operations(moduleId: $moduleId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useOperationsQuery__
 *
 * To run a query within a React component, call `useOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useOperationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperationsQuery,
    OperationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperationsQuery, OperationsQueryVariables>(
    OperationsDocument,
    options,
  );
}
export function useOperationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperationsQuery,
    OperationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperationsQuery, OperationsQueryVariables>(
    OperationsDocument,
    options,
  );
}
export type OperationsQueryHookResult = ReturnType<typeof useOperationsQuery>;
export type OperationsLazyQueryHookResult = ReturnType<
  typeof useOperationsLazyQuery
>;
export type OperationsQueryResult = Apollo.QueryResult<
  OperationsQuery,
  OperationsQueryVariables
>;
export const ModulesDocument = gql`
  query Modules($organizationId: String!) {
    modules(organizationId: $organizationId) {
      __typename
      createdAt
      updatedAt
      id
      position
      name
      totalOperations
    }
  }
`;

/**
 * __useModulesQuery__
 *
 * To run a query within a React component, call `useModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useModulesQuery(
  baseOptions: Apollo.QueryHookOptions<ModulesQuery, ModulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModulesQuery, ModulesQueryVariables>(
    ModulesDocument,
    options,
  );
}
export function useModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesQuery,
    ModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModulesQuery, ModulesQueryVariables>(
    ModulesDocument,
    options,
  );
}
export type ModulesQueryHookResult = ReturnType<typeof useModulesQuery>;
export type ModulesLazyQueryHookResult = ReturnType<typeof useModulesLazyQuery>;
export type ModulesQueryResult = Apollo.QueryResult<
  ModulesQuery,
  ModulesQueryVariables
>;
export const ModuleDocument = gql`
  query Module($moduleId: String!) {
    module(moduleId: $moduleId) {
      __typename
      createdAt
      updatedAt
      id
      position
      totalOperations
      goal
      name
      operations {
        __typename
        ...OperationDetails
      }
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useModuleQuery__
 *
 * To run a query within a React component, call `useModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useModuleQuery(
  baseOptions: Apollo.QueryHookOptions<ModuleQuery, ModuleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModuleQuery, ModuleQueryVariables>(
    ModuleDocument,
    options,
  );
}
export function useModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ModuleQuery, ModuleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModuleQuery, ModuleQueryVariables>(
    ModuleDocument,
    options,
  );
}
export type ModuleQueryHookResult = ReturnType<typeof useModuleQuery>;
export type ModuleLazyQueryHookResult = ReturnType<typeof useModuleLazyQuery>;
export type ModuleQueryResult = Apollo.QueryResult<
  ModuleQuery,
  ModuleQueryVariables
>;
export const UpdateModuleDocument = gql`
  mutation UpdateModule($data: UpdateModuleInput!) {
    updateModule(data: $data) {
      __typename
      createdAt
      updatedAt
      id
      position
      totalOperations
      goal
      name
    }
  }
`;
export type UpdateModuleMutationFn = Apollo.MutationFunction<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >(UpdateModuleDocument, options);
}
export type UpdateModuleMutationHookResult = ReturnType<
  typeof useUpdateModuleMutation
>;
export type UpdateModuleMutationResult =
  Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;
export const RestTimeDocument = gql`
  query RestTime($moduleId: String!) {
    restTimes(moduleId: $moduleId) {
      id
      startHour
      startMin
      endHour
      endMin
    }
  }
`;

/**
 * __useRestTimeQuery__
 *
 * To run a query within a React component, call `useRestTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestTimeQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useRestTimeQuery(
  baseOptions: Apollo.QueryHookOptions<RestTimeQuery, RestTimeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RestTimeQuery, RestTimeQueryVariables>(
    RestTimeDocument,
    options,
  );
}
export function useRestTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestTimeQuery,
    RestTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RestTimeQuery, RestTimeQueryVariables>(
    RestTimeDocument,
    options,
  );
}
export type RestTimeQueryHookResult = ReturnType<typeof useRestTimeQuery>;
export type RestTimeLazyQueryHookResult = ReturnType<
  typeof useRestTimeLazyQuery
>;
export type RestTimeQueryResult = Apollo.QueryResult<
  RestTimeQuery,
  RestTimeQueryVariables
>;
export const CreateRestTimeDocument = gql`
  mutation CreateRestTime($data: CreateRestTimeInput!) {
    createRestTime(data: $data) {
      __typename
      createdAt
      endHour
      endMin
      id
      startHour
      startMin
      updatedAt
    }
  }
`;
export type CreateRestTimeMutationFn = Apollo.MutationFunction<
  CreateRestTimeMutation,
  CreateRestTimeMutationVariables
>;

/**
 * __useCreateRestTimeMutation__
 *
 * To run a mutation, you first call `useCreateRestTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestTimeMutation, { data, loading, error }] = useCreateRestTimeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRestTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRestTimeMutation,
    CreateRestTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRestTimeMutation,
    CreateRestTimeMutationVariables
  >(CreateRestTimeDocument, options);
}
export type CreateRestTimeMutationHookResult = ReturnType<
  typeof useCreateRestTimeMutation
>;
export type CreateRestTimeMutationResult =
  Apollo.MutationResult<CreateRestTimeMutation>;
export type CreateRestTimeMutationOptions = Apollo.BaseMutationOptions<
  CreateRestTimeMutation,
  CreateRestTimeMutationVariables
>;
export const UpdateRestTimeDocument = gql`
  mutation UpdateRestTime($data: UpdateRestTimeInput!) {
    updateRestTime(data: $data) {
      __typename
      createdAt
      endHour
      endMin
      id
      startHour
      startMin
      updatedAt
    }
  }
`;
export type UpdateRestTimeMutationFn = Apollo.MutationFunction<
  UpdateRestTimeMutation,
  UpdateRestTimeMutationVariables
>;

/**
 * __useUpdateRestTimeMutation__
 *
 * To run a mutation, you first call `useUpdateRestTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestTimeMutation, { data, loading, error }] = useUpdateRestTimeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRestTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestTimeMutation,
    UpdateRestTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRestTimeMutation,
    UpdateRestTimeMutationVariables
  >(UpdateRestTimeDocument, options);
}
export type UpdateRestTimeMutationHookResult = ReturnType<
  typeof useUpdateRestTimeMutation
>;
export type UpdateRestTimeMutationResult =
  Apollo.MutationResult<UpdateRestTimeMutation>;
export type UpdateRestTimeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestTimeMutation,
  UpdateRestTimeMutationVariables
>;
export const DeleteRestTimeDocument = gql`
  mutation DeleteRestTime($restTimeId: String!) {
    deleteRestTime(restTimeId: $restTimeId) {
      __typename
      createdAt
      endHour
      endMin
      id
      startHour
      startMin
      updatedAt
    }
  }
`;
export type DeleteRestTimeMutationFn = Apollo.MutationFunction<
  DeleteRestTimeMutation,
  DeleteRestTimeMutationVariables
>;

/**
 * __useDeleteRestTimeMutation__
 *
 * To run a mutation, you first call `useDeleteRestTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestTimeMutation, { data, loading, error }] = useDeleteRestTimeMutation({
 *   variables: {
 *      restTimeId: // value for 'restTimeId'
 *   },
 * });
 */
export function useDeleteRestTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRestTimeMutation,
    DeleteRestTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRestTimeMutation,
    DeleteRestTimeMutationVariables
  >(DeleteRestTimeDocument, options);
}
export type DeleteRestTimeMutationHookResult = ReturnType<
  typeof useDeleteRestTimeMutation
>;
export type DeleteRestTimeMutationResult =
  Apollo.MutationResult<DeleteRestTimeMutation>;
export type DeleteRestTimeMutationOptions = Apollo.BaseMutationOptions<
  DeleteRestTimeMutation,
  DeleteRestTimeMutationVariables
>;
export const RestTimeExistsDocument = gql`
  query RestTimeExists($restTimeId: String!) {
    restTimeExists(restTimeId: $restTimeId) {
      __typename
      createdAt
      endHour
      endMin
      id
      startHour
      startMin
      updatedAt
    }
  }
`;

/**
 * __useRestTimeExistsQuery__
 *
 * To run a query within a React component, call `useRestTimeExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestTimeExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestTimeExistsQuery({
 *   variables: {
 *      restTimeId: // value for 'restTimeId'
 *   },
 * });
 */
export function useRestTimeExistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestTimeExistsQuery,
    RestTimeExistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RestTimeExistsQuery, RestTimeExistsQueryVariables>(
    RestTimeExistsDocument,
    options,
  );
}
export function useRestTimeExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestTimeExistsQuery,
    RestTimeExistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RestTimeExistsQuery, RestTimeExistsQueryVariables>(
    RestTimeExistsDocument,
    options,
  );
}
export type RestTimeExistsQueryHookResult = ReturnType<
  typeof useRestTimeExistsQuery
>;
export type RestTimeExistsLazyQueryHookResult = ReturnType<
  typeof useRestTimeExistsLazyQuery
>;
export type RestTimeExistsQueryResult = Apollo.QueryResult<
  RestTimeExistsQuery,
  RestTimeExistsQueryVariables
>;
export const UpdateOperationDocument = gql`
  mutation UpdateOperation($data: UpdateOperationInput!) {
    updateOperation(data: $data) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type UpdateOperationMutationFn = Apollo.MutationFunction<
  UpdateOperationMutation,
  UpdateOperationMutationVariables
>;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationMutation, { data, loading, error }] = useUpdateOperationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOperationMutation,
    UpdateOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOperationMutation,
    UpdateOperationMutationVariables
  >(UpdateOperationDocument, options);
}
export type UpdateOperationMutationHookResult = ReturnType<
  typeof useUpdateOperationMutation
>;
export type UpdateOperationMutationResult =
  Apollo.MutationResult<UpdateOperationMutation>;
export type UpdateOperationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperationMutation,
  UpdateOperationMutationVariables
>;
export const UpdateOperationsDocument = gql`
  mutation UpdateOperations($data: UpdateOperationsInput!) {
    updateOperations(data: $data) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type UpdateOperationsMutationFn = Apollo.MutationFunction<
  UpdateOperationsMutation,
  UpdateOperationsMutationVariables
>;

/**
 * __useUpdateOperationsMutation__
 *
 * To run a mutation, you first call `useUpdateOperationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationsMutation, { data, loading, error }] = useUpdateOperationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOperationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOperationsMutation,
    UpdateOperationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOperationsMutation,
    UpdateOperationsMutationVariables
  >(UpdateOperationsDocument, options);
}
export type UpdateOperationsMutationHookResult = ReturnType<
  typeof useUpdateOperationsMutation
>;
export type UpdateOperationsMutationResult =
  Apollo.MutationResult<UpdateOperationsMutation>;
export type UpdateOperationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperationsMutation,
  UpdateOperationsMutationVariables
>;
export const SetOperationAsReferenceDocument = gql`
  mutation SetOperationAsReference($operationId: String!) {
    setOperationAsReference(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type SetOperationAsReferenceMutationFn = Apollo.MutationFunction<
  SetOperationAsReferenceMutation,
  SetOperationAsReferenceMutationVariables
>;

/**
 * __useSetOperationAsReferenceMutation__
 *
 * To run a mutation, you first call `useSetOperationAsReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOperationAsReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOperationAsReferenceMutation, { data, loading, error }] = useSetOperationAsReferenceMutation({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useSetOperationAsReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOperationAsReferenceMutation,
    SetOperationAsReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOperationAsReferenceMutation,
    SetOperationAsReferenceMutationVariables
  >(SetOperationAsReferenceDocument, options);
}
export type SetOperationAsReferenceMutationHookResult = ReturnType<
  typeof useSetOperationAsReferenceMutation
>;
export type SetOperationAsReferenceMutationResult =
  Apollo.MutationResult<SetOperationAsReferenceMutation>;
export type SetOperationAsReferenceMutationOptions = Apollo.BaseMutationOptions<
  SetOperationAsReferenceMutation,
  SetOperationAsReferenceMutationVariables
>;
export const RemoveOperationAsReferenceDocument = gql`
  mutation RemoveOperationAsReference($operationId: String!) {
    removeOperationAsReference(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type RemoveOperationAsReferenceMutationFn = Apollo.MutationFunction<
  RemoveOperationAsReferenceMutation,
  RemoveOperationAsReferenceMutationVariables
>;

/**
 * __useRemoveOperationAsReferenceMutation__
 *
 * To run a mutation, you first call `useRemoveOperationAsReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOperationAsReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOperationAsReferenceMutation, { data, loading, error }] = useRemoveOperationAsReferenceMutation({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useRemoveOperationAsReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOperationAsReferenceMutation,
    RemoveOperationAsReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOperationAsReferenceMutation,
    RemoveOperationAsReferenceMutationVariables
  >(RemoveOperationAsReferenceDocument, options);
}
export type RemoveOperationAsReferenceMutationHookResult = ReturnType<
  typeof useRemoveOperationAsReferenceMutation
>;
export type RemoveOperationAsReferenceMutationResult =
  Apollo.MutationResult<RemoveOperationAsReferenceMutation>;
export type RemoveOperationAsReferenceMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveOperationAsReferenceMutation,
    RemoveOperationAsReferenceMutationVariables
  >;
export const RemoveOperationOperatorDocument = gql`
  mutation RemoveOperationOperator($operationId: String!) {
    removeOperationOperator(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type RemoveOperationOperatorMutationFn = Apollo.MutationFunction<
  RemoveOperationOperatorMutation,
  RemoveOperationOperatorMutationVariables
>;

/**
 * __useRemoveOperationOperatorMutation__
 *
 * To run a mutation, you first call `useRemoveOperationOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOperationOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOperationOperatorMutation, { data, loading, error }] = useRemoveOperationOperatorMutation({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useRemoveOperationOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOperationOperatorMutation,
    RemoveOperationOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOperationOperatorMutation,
    RemoveOperationOperatorMutationVariables
  >(RemoveOperationOperatorDocument, options);
}
export type RemoveOperationOperatorMutationHookResult = ReturnType<
  typeof useRemoveOperationOperatorMutation
>;
export type RemoveOperationOperatorMutationResult =
  Apollo.MutationResult<RemoveOperationOperatorMutation>;
export type RemoveOperationOperatorMutationOptions = Apollo.BaseMutationOptions<
  RemoveOperationOperatorMutation,
  RemoveOperationOperatorMutationVariables
>;
export const RemoveOperatorOperationDocument = gql`
  mutation RemoveOperatorOperation($operationId: String!) {
    removeOperatorOperation(operationId: $operationId) {
      __typename
      activationCode
      active
      createdAt
      id
      name
      updatedAt
    }
  }
`;
export type RemoveOperatorOperationMutationFn = Apollo.MutationFunction<
  RemoveOperatorOperationMutation,
  RemoveOperatorOperationMutationVariables
>;

/**
 * __useRemoveOperatorOperationMutation__
 *
 * To run a mutation, you first call `useRemoveOperatorOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOperatorOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOperatorOperationMutation, { data, loading, error }] = useRemoveOperatorOperationMutation({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useRemoveOperatorOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOperatorOperationMutation,
    RemoveOperatorOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOperatorOperationMutation,
    RemoveOperatorOperationMutationVariables
  >(RemoveOperatorOperationDocument, options);
}
export type RemoveOperatorOperationMutationHookResult = ReturnType<
  typeof useRemoveOperatorOperationMutation
>;
export type RemoveOperatorOperationMutationResult =
  Apollo.MutationResult<RemoveOperatorOperationMutation>;
export type RemoveOperatorOperationMutationOptions = Apollo.BaseMutationOptions<
  RemoveOperatorOperationMutation,
  RemoveOperatorOperationMutationVariables
>;
export const FindOperatorOperationDocument = gql`
  query FindOperatorOperation($operationId: String!) {
    findOperatorOperation(operationId: $operationId) {
      __typename
      activationCode
      active
      createdAt
      id
      name
      operation {
        position
        id
      }
      updatedAt
    }
  }
`;

/**
 * __useFindOperatorOperationQuery__
 *
 * To run a query within a React component, call `useFindOperatorOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOperatorOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOperatorOperationQuery({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useFindOperatorOperationQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOperatorOperationQuery,
    FindOperatorOperationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindOperatorOperationQuery,
    FindOperatorOperationQueryVariables
  >(FindOperatorOperationDocument, options);
}
export function useFindOperatorOperationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOperatorOperationQuery,
    FindOperatorOperationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindOperatorOperationQuery,
    FindOperatorOperationQueryVariables
  >(FindOperatorOperationDocument, options);
}
export type FindOperatorOperationQueryHookResult = ReturnType<
  typeof useFindOperatorOperationQuery
>;
export type FindOperatorOperationLazyQueryHookResult = ReturnType<
  typeof useFindOperatorOperationLazyQuery
>;
export type FindOperatorOperationQueryResult = Apollo.QueryResult<
  FindOperatorOperationQuery,
  FindOperatorOperationQueryVariables
>;
export const UpdateOperatorDocument = gql`
  mutation UpdateOperator($data: UpdateOperatorInput!) {
    updateOperator(data: $data) {
      __typename
      activationCode
      active
      createdAt
      id
      name
      operation {
        position
        id
        name
      }
      updatedAt
    }
  }
`;
export type UpdateOperatorMutationFn = Apollo.MutationFunction<
  UpdateOperatorMutation,
  UpdateOperatorMutationVariables
>;

/**
 * __useUpdateOperatorMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorMutation, { data, loading, error }] = useUpdateOperatorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOperatorMutation,
    UpdateOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOperatorMutation,
    UpdateOperatorMutationVariables
  >(UpdateOperatorDocument, options);
}
export type UpdateOperatorMutationHookResult = ReturnType<
  typeof useUpdateOperatorMutation
>;
export type UpdateOperatorMutationResult =
  Apollo.MutationResult<UpdateOperatorMutation>;
export type UpdateOperatorMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperatorMutation,
  UpdateOperatorMutationVariables
>;
export const OperatorDocument = gql`
  query operator($operatorId: String!) {
    operator(operatorId: $operatorId) {
      __typename
      activationCode
      active
      createdAt
      id
      name
      operation {
        position
        id
        name
      }
      updatedAt
    }
  }
`;

/**
 * __useOperatorQuery__
 *
 * To run a query within a React component, call `useOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorQuery({
 *   variables: {
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useOperatorQuery(
  baseOptions: Apollo.QueryHookOptions<OperatorQuery, OperatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperatorQuery, OperatorQueryVariables>(
    OperatorDocument,
    options,
  );
}
export function useOperatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorQuery,
    OperatorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperatorQuery, OperatorQueryVariables>(
    OperatorDocument,
    options,
  );
}
export type OperatorQueryHookResult = ReturnType<typeof useOperatorQuery>;
export type OperatorLazyQueryHookResult = ReturnType<
  typeof useOperatorLazyQuery
>;
export type OperatorQueryResult = Apollo.QueryResult<
  OperatorQuery,
  OperatorQueryVariables
>;
export const CreateOperatorDocument = gql`
  mutation CreateOperator($data: CreateOperatorInput!) {
    createOperator(data: $data) {
      id
    }
  }
`;
export type CreateOperatorMutationFn = Apollo.MutationFunction<
  CreateOperatorMutation,
  CreateOperatorMutationVariables
>;

/**
 * __useCreateOperatorMutation__
 *
 * To run a mutation, you first call `useCreateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperatorMutation, { data, loading, error }] = useCreateOperatorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >(CreateOperatorDocument, options);
}
export type CreateOperatorMutationHookResult = ReturnType<
  typeof useCreateOperatorMutation
>;
export type CreateOperatorMutationResult =
  Apollo.MutationResult<CreateOperatorMutation>;
export type CreateOperatorMutationOptions = Apollo.BaseMutationOptions<
  CreateOperatorMutation,
  CreateOperatorMutationVariables
>;
export const DeleteOperatorDocument = gql`
  mutation DeleteOperator($operatorId: String!) {
    deleteOperator(operatorId: $operatorId) {
      id
    }
  }
`;
export type DeleteOperatorMutationFn = Apollo.MutationFunction<
  DeleteOperatorMutation,
  DeleteOperatorMutationVariables
>;

/**
 * __useDeleteOperatorMutation__
 *
 * To run a mutation, you first call `useDeleteOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperatorMutation, { data, loading, error }] = useDeleteOperatorMutation({
 *   variables: {
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useDeleteOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOperatorMutation,
    DeleteOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOperatorMutation,
    DeleteOperatorMutationVariables
  >(DeleteOperatorDocument, options);
}
export type DeleteOperatorMutationHookResult = ReturnType<
  typeof useDeleteOperatorMutation
>;
export type DeleteOperatorMutationResult =
  Apollo.MutationResult<DeleteOperatorMutation>;
export type DeleteOperatorMutationOptions = Apollo.BaseMutationOptions<
  DeleteOperatorMutation,
  DeleteOperatorMutationVariables
>;
export const OperatorsDocument = gql`
  query Operators($organizationId: String!) {
    operators(organizationId: $organizationId) {
      id
      activationCode
      active
      createdAt
      id
      name
      operation {
        position
        id
        name
      }
      updatedAt
    }
  }
`;

/**
 * __useOperatorsQuery__
 *
 * To run a query within a React component, call `useOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOperatorsQuery(
  baseOptions: Apollo.QueryHookOptions<OperatorsQuery, OperatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperatorsQuery, OperatorsQueryVariables>(
    OperatorsDocument,
    options,
  );
}
export function useOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorsQuery,
    OperatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperatorsQuery, OperatorsQueryVariables>(
    OperatorsDocument,
    options,
  );
}
export type OperatorsQueryHookResult = ReturnType<typeof useOperatorsQuery>;
export type OperatorsLazyQueryHookResult = ReturnType<
  typeof useOperatorsLazyQuery
>;
export type OperatorsQueryResult = Apollo.QueryResult<
  OperatorsQuery,
  OperatorsQueryVariables
>;
export const OperatorByCodeDocument = gql`
  query OperatorByCode($activationCode: Int!) {
    operatorByCode(activationCode: $activationCode) {
      id
      activationCode
      active
      createdAt
      id
      name
      operation {
        position
        id
        module {
          name
          id
          goal
        }
      }
      updatedAt
    }
  }
`;

/**
 * __useOperatorByCodeQuery__
 *
 * To run a query within a React component, call `useOperatorByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorByCodeQuery({
 *   variables: {
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useOperatorByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatorByCodeQuery,
    OperatorByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperatorByCodeQuery, OperatorByCodeQueryVariables>(
    OperatorByCodeDocument,
    options,
  );
}
export function useOperatorByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorByCodeQuery,
    OperatorByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperatorByCodeQuery, OperatorByCodeQueryVariables>(
    OperatorByCodeDocument,
    options,
  );
}
export type OperatorByCodeQueryHookResult = ReturnType<
  typeof useOperatorByCodeQuery
>;
export type OperatorByCodeLazyQueryHookResult = ReturnType<
  typeof useOperatorByCodeLazyQuery
>;
export type OperatorByCodeQueryResult = Apollo.QueryResult<
  OperatorByCodeQuery,
  OperatorByCodeQueryVariables
>;
export const CreateHistoryDocument = gql`
  mutation CreateHistory($data: CreateHistoryInput!) {
    createHistory(data: $data) {
      __typename
      createdAt
      day
      goal
      hour
      id
      operation {
        position
        id
      }
      total
      updatedAt
    }
  }
`;
export type CreateHistoryMutationFn = Apollo.MutationFunction<
  CreateHistoryMutation,
  CreateHistoryMutationVariables
>;

/**
 * __useCreateHistoryMutation__
 *
 * To run a mutation, you first call `useCreateHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHistoryMutation, { data, loading, error }] = useCreateHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHistoryMutation,
    CreateHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHistoryMutation,
    CreateHistoryMutationVariables
  >(CreateHistoryDocument, options);
}
export type CreateHistoryMutationHookResult = ReturnType<
  typeof useCreateHistoryMutation
>;
export type CreateHistoryMutationResult =
  Apollo.MutationResult<CreateHistoryMutation>;
export type CreateHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateHistoryMutation,
  CreateHistoryMutationVariables
>;
export const OperationUpdatedDocument = gql`
  subscription OperationUpdated($operationId: String!) {
    operationUpdated(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useOperationUpdatedSubscription__
 *
 * To run a query within a React component, call `useOperationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOperationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationUpdatedSubscription({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useOperationUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OperationUpdatedSubscription,
    OperationUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OperationUpdatedSubscription,
    OperationUpdatedSubscriptionVariables
  >(OperationUpdatedDocument, options);
}
export type OperationUpdatedSubscriptionHookResult = ReturnType<
  typeof useOperationUpdatedSubscription
>;
export type OperationUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OperationUpdatedSubscription>;
export const RestTimesUpdatedDocument = gql`
  subscription RestTimesUpdated($moduleId: String!) {
    restTimeUpdated(moduleId: $moduleId) {
      __typename
      id
      startHour
      startMin
      endHour
      endMin
      module {
        name
        id
        goal
      }
    }
  }
`;

/**
 * __useRestTimesUpdatedSubscription__
 *
 * To run a query within a React component, call `useRestTimesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestTimesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestTimesUpdatedSubscription({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useRestTimesUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RestTimesUpdatedSubscription,
    RestTimesUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RestTimesUpdatedSubscription,
    RestTimesUpdatedSubscriptionVariables
  >(RestTimesUpdatedDocument, options);
}
export type RestTimesUpdatedSubscriptionHookResult = ReturnType<
  typeof useRestTimesUpdatedSubscription
>;
export type RestTimesUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<RestTimesUpdatedSubscription>;
export const HistoryOperationDocument = gql`
  query HistoryOperation($day: String!, $operationId: String!) {
    historyOperation(day: $day, operationId: $operationId) {
      __typename
      id
      day
      hour
      goal
      total
    }
  }
`;

/**
 * __useHistoryOperationQuery__
 *
 * To run a query within a React component, call `useHistoryOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryOperationQuery({
 *   variables: {
 *      day: // value for 'day'
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useHistoryOperationQuery(
  baseOptions: Apollo.QueryHookOptions<
    HistoryOperationQuery,
    HistoryOperationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoryOperationQuery, HistoryOperationQueryVariables>(
    HistoryOperationDocument,
    options,
  );
}
export function useHistoryOperationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HistoryOperationQuery,
    HistoryOperationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HistoryOperationQuery,
    HistoryOperationQueryVariables
  >(HistoryOperationDocument, options);
}
export type HistoryOperationQueryHookResult = ReturnType<
  typeof useHistoryOperationQuery
>;
export type HistoryOperationLazyQueryHookResult = ReturnType<
  typeof useHistoryOperationLazyQuery
>;
export type HistoryOperationQueryResult = Apollo.QueryResult<
  HistoryOperationQuery,
  HistoryOperationQueryVariables
>;
export const OperationsUpdatedDocument = gql`
  subscription OperationsUpdated($moduleId: String!) {
    operationsUpdated(moduleId: $moduleId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useOperationsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOperationsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOperationsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsUpdatedSubscription({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useOperationsUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OperationsUpdatedSubscription,
    OperationsUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OperationsUpdatedSubscription,
    OperationsUpdatedSubscriptionVariables
  >(OperationsUpdatedDocument, options);
}
export type OperationsUpdatedSubscriptionHookResult = ReturnType<
  typeof useOperationsUpdatedSubscription
>;
export type OperationsUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OperationsUpdatedSubscription>;
export const OperatorsUpdatedDocument = gql`
  subscription OperatorsUpdated($organizationId: String!) {
    operatorsUpdated(organizationId: $organizationId) {
      __typename
      id
      activationCode
      active
      id
      name
      operation {
        position
        id
        name
      }
      organization {
        id
      }
    }
  }
`;

/**
 * __useOperatorsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOperatorsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsUpdatedSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOperatorsUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OperatorsUpdatedSubscription,
    OperatorsUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OperatorsUpdatedSubscription,
    OperatorsUpdatedSubscriptionVariables
  >(OperatorsUpdatedDocument, options);
}
export type OperatorsUpdatedSubscriptionHookResult = ReturnType<
  typeof useOperatorsUpdatedSubscription
>;
export type OperatorsUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OperatorsUpdatedSubscription>;
export const RegisterEventDocument = gql`
  mutation RegisterEvent($data: CreateRecordCompletedInput!) {
    registerEvent(data: $data) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;
export type RegisterEventMutationFn = Apollo.MutationFunction<
  RegisterEventMutation,
  RegisterEventMutationVariables
>;

/**
 * __useRegisterEventMutation__
 *
 * To run a mutation, you first call `useRegisterEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEventMutation, { data, loading, error }] = useRegisterEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterEventMutation,
    RegisterEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterEventMutation,
    RegisterEventMutationVariables
  >(RegisterEventDocument, options);
}
export type RegisterEventMutationHookResult = ReturnType<
  typeof useRegisterEventMutation
>;
export type RegisterEventMutationResult =
  Apollo.MutationResult<RegisterEventMutation>;
export type RegisterEventMutationOptions = Apollo.BaseMutationOptions<
  RegisterEventMutation,
  RegisterEventMutationVariables
>;
export const RegisteredEventDocument = gql`
  subscription registeredEvent($operationId: String!) {
    registeredEvent(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useRegisteredEventSubscription__
 *
 * To run a query within a React component, call `useRegisteredEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredEventSubscription({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useRegisteredEventSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RegisteredEventSubscription,
    RegisteredEventSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RegisteredEventSubscription,
    RegisteredEventSubscriptionVariables
  >(RegisteredEventDocument, options);
}
export type RegisteredEventSubscriptionHookResult = ReturnType<
  typeof useRegisteredEventSubscription
>;
export type RegisteredEventSubscriptionResult =
  Apollo.SubscriptionResult<RegisteredEventSubscription>;
export const CreateUserDocument = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data) {
      __typename
      createdAt
      email
      firstname
      id
      lastname
      role
      updatedAt
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($data: CreateOrganizationInput!) {
    createOrganization(data: $data) {
      __typename
      createdAt
      id
      name
      timezone
      updatedAt
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const UpdateHourDocument = gql`
  subscription UpdateHour($operationId: String!) {
    updateHour(operationId: $operationId) {
      __typename
      ...OperationDetails
    }
  }
  ${OperationDetailsFragmentDoc}
`;

/**
 * __useUpdateHourSubscription__
 *
 * To run a query within a React component, call `useUpdateHourSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHourSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHourSubscription({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useUpdateHourSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdateHourSubscription,
    UpdateHourSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UpdateHourSubscription,
    UpdateHourSubscriptionVariables
  >(UpdateHourDocument, options);
}
export type UpdateHourSubscriptionHookResult = ReturnType<
  typeof useUpdateHourSubscription
>;
export type UpdateHourSubscriptionResult =
  Apollo.SubscriptionResult<UpdateHourSubscription>;
export const GetUsersDocument = gql`
  query GetUsers {
    getUsers {
      __typename
      activationCode
      createdAt
      email
      firstname
      id
      lastname
      organization {
        createdAt
        id
        name
        timezone
        updatedAt
      }
      role
      sessions {
        createdAt
        id
        token
        updatedAt
      }
      updatedAt
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
