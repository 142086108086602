import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { useUserStore } from '@/stores/user';

export const Landing = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useUserStore();

  const handleStart = async () => {
    const token = localStorage.getItem('ConfecTronic-Token');
    const emailUser = localStorage.getItem('ConfecTronic-User');
    const isActive = token && emailUser && user;
    if (isActive) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    const handleAuth = async () => {
      const token = localStorage.getItem('ConfecTronic-Token');
      const emailUser = localStorage.getItem('ConfecTronic-User');
      console.log({ token, emailUser, user });
      const isActive = token && emailUser && user;
      if (isActive) {
        navigate('/app');
        setLoading(false);
      }

      if (!isActive) {
        setLoading(false);
      }
    };
    handleAuth();
  }, [user]);

  return (
    <>
      <Head description="Fluenta" />
      <div className="flex h-[100vh] items-center bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Bienvenido</span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              {!loading ? (
                <Button
                  onClick={handleStart}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  }>
                  Iniciar sesión
                </Button>
              ) : (
                <div>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
