import { Table } from '@/components/Elements';

export type Device = {
  id: string;
  name: string;
  code: string;
};

export type DevicesTableProps = {
  name: string;
};

const data: Device[] = [
  {
    id: '1',
    name: 'Dispositivo 1',
    code: '123456',
  },
  {
    id: '2',
    name: 'Dispositivo 3',
    code: '456789',
  },
  {
    id: '3',
    name: 'Dispositivo 4',
    code: '987654',
  },
];

export const DevicesTable: React.FC<DevicesTableProps> = () => {
  return (
    <Table<Device>
      data={data}
      height={'min-h-[500px]'}
      columns={[
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Code',
          field: 'code',
        },
        {
          title: '',
          field: 'id',
          Cell({ entry: { id } }) {
            return <p>asignar {id}</p>;
          },
        },
      ]}
    />
  );
};
