import { ClockIcon, LogoutIcon, RefreshIcon } from '@heroicons/react/outline';
import {
  CalculatorIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { Avatar, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import type { Identifier } from 'dnd-core';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { io, Socket } from 'socket.io-client';

import { CURRENT_API, SOCKET_IO_CONFIG } from '@/client';
import { Dropdown } from '@/components/Elements';
import { Loading } from '@/components/Loading';
import {
  Operation,
  Operation as OperationType,
  Operator,
  RestTime,
  useFindOperatorOperationLazyQuery,
  useOperationLazyQuery,
  useOperatorLazyQuery,
  useRemoveOperationAsReferenceMutation,
  useRemoveOperationOperatorMutation,
  useRemoveOperatorOperationMutation,
  useRestTimeLazyQuery,
  useSetOperationAsReferenceMutation,
  useUpdateOperationMutation,
  useUpdateOperatorMutation,
} from '@/generated/graphql';
import { useDate } from '@/hooks/useDate';
import { useProductivityColor } from '@/hooks/useProductivityColor';
import { useNotificationStore } from '@/stores/notifications';
import { useReferenceStore } from '@/stores/reference';
import { useUserStore } from '@/stores/user';

import { ItemTypes } from '../constants';
import { AccumulateModal } from './AccumulateModal';
import { CleanOperationConfirmModal } from './CleanOperationConfirmModal';
import { HistoryModal } from './HistoryModal';
import { BreaksType } from './ModuleComponent';
import { OperationModal } from './OperationModal';

const getOptions = (hasUser = false) => {
  const options = [
    {
      id: 'edit',
      label: 'Editar',
      icon: <PencilIcon width={20} height={20} />,
    },
    {
      id: 'refresh',
      label: 'Refrescar operación',
      icon: <RefreshIcon width={20} height={20} />,
    },
    {
      id: 'reference',
      label: 'Tablero',
      icon: <EyeIcon width={20} height={20} />,
    },
    {
      id: 'accum',
      label: 'Cambiar acumulado',
      icon: <CalculatorIcon width={20} height={20} />,
    },
    {
      id: 'history',
      label: 'Ver Reporte',
      icon: <ClockIcon width={20} height={20} />,
    },
    {
      id: 'delete',
      label: 'Limpiar operación',
      icon: <TrashIcon width={20} height={20} />,
    },
  ];

  if (hasUser) {
    options.push({
      id: 'user_offline',
      label: 'Desconectar operador(a)',
      icon: <LogoutIcon width={20} height={20} color="red" />,
    });
  }

  return options;
};

export type OperationProps = {
  className?: string;
  id: string;
  name: string;
  index: number;
  goal: number;
  total?: number;
  completed?: number;
  reference: string | undefined;
  moduleId: string;
  breaks: BreaksType[];
  operation: OperationType;
  isPreview: boolean;
  operationData: Operation;
  moduleIndex: number;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const REGISTERED_EVENT = 'registeredEvent';
const OPERATION_UPDATED = 'operationUpdated';
const UPDATE_HOUR = 'updateHour';

const OperationComponent: React.FC<OperationProps> = ({
  id,
  name,
  index,
  total,
  completed = 0,
  reference,
  moduleId,
  breaks,
  isPreview = false,
  operationData,
  moduleIndex,
}) => {
  // const {
  //   data: operationData,
  //   loading: operationLoading,
  //   refetch,
  // } = useOperationQuery({
  //   variables: {
  //     operationId: id,
  //   },
  // });

  const registerEventSocket = useRef<Socket>();
  const operatorOnlineSocket = useRef<Socket | null>(null);
  const updatedOperationSocket = useRef<Socket>();

  // const { socket: operatorConnected, messages: operatorConnectedMessages} = useSocketIo()

  const [queryFindOperatorOperation] = useFindOperatorOperationLazyQuery();
  const [updateOperatorMutation] = useUpdateOperatorMutation();
  const [updateOperationMutation] = useUpdateOperationMutation();
  const [setOperationAsReference] = useSetOperationAsReferenceMutation();
  const [removeOperationAsReference] = useRemoveOperationAsReferenceMutation();
  const [queryOperator] = useOperatorLazyQuery();
  const [removeOperationOperator] = useRemoveOperationOperatorMutation();
  const [removeOperatorOperaton] = useRemoveOperatorOperationMutation();
  const [queryRestTime] = useRestTimeLazyQuery();
  const [operationQuery] = useOperationLazyQuery();

  const { updateReference, getReferenceByOperationId } = useReferenceStore();
  const [isOperationModalOpen, toggleOperationModal] = useState(false);
  const [isAccumulateModalOpen, toggleAccumulateModal] = useState(false);
  const [isHistoryModalOpen, toggleHistoryModal] = useState(false);
  const [confirmationModal, toggleConfirmationModal] = useState(false);
  const [operation, setOperation] = useState<OperationType>(operationData);
  const [loading, setLoading] = useState(false);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [operatorOnline, setOperatorOnline] = useState<undefined | boolean>();

  const { organization } = useUserStore();

  const { time, hour } = useDate();
  const { color, setOperationDetails } = useProductivityColor();
  const colorRef = useRef<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const operationRef = useRef<OperationType | null>(null);
  const breaksRef = useRef<RestTime[] | null>();

  const [{ handlerId, isOver }, drop] = useDrop<
    DragItem,
    void,
    {
      handlerId: Identifier | null;
      isOver: boolean | null;
      canDrop: boolean | null;
    }
  >({
    accept: ItemTypes.OPERATOR,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item) {
      const handleDropItem = async () => {
        if (operationRef.current?.operator?.id === item.id) {
          useNotificationStore.getState().addNotification({
            title: 'El usuario se encuentra en esta operación',
            type: 'error',
            message:
              'No es posible agregar nuevamente al operador en la misma operación.',
          });
          return;
        }

        if (operationRef.current?.operator?.id) {
          useNotificationStore.getState().addNotification({
            title: 'Esta operación ya tiene un operador.',
            type: 'error',
            message:
              'Debes de quitar al operador actual para asignar uno nuevo.',
          });
          return;
        }

        if (!operationRef.current?.operator?.id) {
          const operatorHasOperation = async () => {
            try {
              const operator = await queryOperator({
                variables: {
                  operatorId: item.id,
                },
              });

              if (operator.data?.operator.operation) {
                useNotificationStore.getState().addNotification({
                  title: 'El usuario ya está en una operación',
                  type: 'error',
                  message:
                    'Primero debes de quitar al usuario de la otra operación',
                });
                return true;
              }
              return false;
            } catch (err) {
              return false;
            }
          };

          if (await operatorHasOperation()) {
            return;
          }
        }

        handleDropOperator(id, item as any, true, true);
      };

      if (!isPreview) handleDropItem();
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.OPERATION,
    item: () => {
      return { ...operation };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleDropOperator = async (
    id: string,
    user: Operator | null,
    deleteOperationOnUser,
    startedLate,
  ) => {
    if (isPreview) return;
    if (user?.id === null) return;
    setLoading(true);
    try {
      // if (deleteOperationOnUser) {
      //   if (operation?.operator?.id) {
      //     await removeOperatorOperaton({
      //       variables: {
      //         operatorId: operation.id,
      //       },
      //     });
      //   }
      // }

      const GOAL = operation?.goalReference as number;
      const hourTz = moment(new Date())
        .tz(organization?.timezone as string)
        .format('H');
      const hourInt = parseInt(hourTz, 10);
      const minutes = new Date().getMinutes();
      const currentBreak = breaks?.find(
        (currentBreak) => currentBreak.startHour === hourInt,
      );

      let operationData: any = {};

      if (GOAL && currentBreak) {
        if (startedLate) {
          if (
            currentBreak.endHour === currentBreak.startHour + 1 &&
            currentBreak.endMin === 0
          ) {
            const totalMinutes = 60 - (60 - currentBreak.startMin);

            const newGoalWithBreak = (totalMinutes * GOAL) / 60;
            const minutesLeft = 60 - minutes;
            const newGoal = (minutesLeft * newGoalWithBreak) / totalMinutes;
            operationData = {
              startedLate: startedLate,
              goal: Math.ceil(newGoal),
              currentHour: new Date().getHours(),
            };
          } else {
            const totalMinutes =
              60 - (currentBreak.endMin - currentBreak.startMin);
            const minutesLeft = 60 - minutes;

            const newGoalWithBreak = (totalMinutes * GOAL) / 60;

            const newGoal = (minutesLeft * newGoalWithBreak) / totalMinutes;

            operationData = {
              startedLate: startedLate,
              goal: Math.ceil(newGoal),
              currentHour: new Date().getHours(),
            };
          }
        } else {
          operationData = {
            startedLate: startedLate,
            goal: operation?.goalReference,
            currentHour: new Date().getHours(),
          };
        }

        const updatedOperator = await updateOperator(user?.id as string, id);

        if (updatedOperator) {
          await updateOperationMutation({
            variables: {
              data: {
                id,
                ...operationData,
              },
            },
            fetchPolicy: 'no-cache',
          });
        }
      } else {
        if (startedLate) {
          const totalMinutes = 60 - minutes;
          const newGoal = (totalMinutes * GOAL) / 60;

          operationData = {
            startedLate: startedLate,
            goal: Math.ceil(newGoal),
            currentHour: new Date().getHours(),
          };
        } else {
          operationData = {
            startedLate: startedLate,
            goal: operation?.goalReference,
            currentHour: new Date().getHours(),
          };
        }

        const updatedOperator = await updateOperator(user?.id as string, id);

        if (updatedOperator) {
          await updateOperationMutation({
            variables: {
              data: {
                id,
                ...operationData,
              },
            },
            fetchPolicy: 'no-cache',
          });
        }
      }
      setLoading(false);
      // refetch();
    } catch (err) {
      console.error('error updating operation', err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo modificar la operación.',
      });
      setLoading(false);
    }
  };

  const updateOperator = async (id: string, newOperation) => {
    if (isPreview) return;
    try {
      if (newOperation) {
        const updatedOperator = await updateOperatorMutation({
          variables: {
            data: {
              id,
              operationId: newOperation,
            },
          },
          fetchPolicy: 'no-cache',
        });
      }

      if (typeof newOperation === 'undefined' || !newOperation) {
        const updateOperation = await updateOperationMutation({
          variables: {
            data: {
              id,
              operatorActive: false,
            },
          },
          fetchPolicy: 'no-cache',
          // optimisticResponse: {
          //   updateOperation: {
          //     __typename: 'Operation',
          //     ...(operation as OperationType),
          //   },
          // },
        });
      }

      return true;
      // refetch();
    } catch (err) {
      console.error('error updating operator', err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo actualizar usuario en operation.',
      });
      return false;
    }
  };

  const operatorOffline = async (operatorId, operationId) => {
    if (isPreview) return;
    try {
      const updatedOperator = await updateOperatorMutation({
        variables: {
          data: {
            id: operatorId,
            active: false,
          },
        },
        fetchPolicy: 'no-cache',
        // optimisticResponse: {
        //   updateOperator: {
        //     __typename: 'Operator',
        //     ...(operation?.operator as Operator),
        //     operation: {
        //       ...(operation as OperationType),
        //     },
        //   },
        // },
      });

      if (updatedOperator.data) {
        const updateOperation = await updateOperationMutation({
          variables: {
            data: {
              id: operationId,
              operatorActive: false,
              startedLate: false,
            },
          },
          fetchPolicy: 'no-cache',
          // optimisticResponse: {
          //   updateOperation: {
          //     __typename: 'Operation',
          //     ...(operation as OperationType),
          //   },
          // },
        });

        if (updateOperation.data) {
          useNotificationStore.getState().addNotification({
            title: 'Éxito',
            type: 'success',
            message: 'Usuario desconectado. Ahora puede iniciar sesión.',
          });
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
      // refetch();
    } catch (err) {
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo desconectar el usuario',
      });
    }
  };

  const cleanOperation = async (id: string) => {
    if (isPreview) return;
    try {
      setLoading(true);

      if (operation.operator) {
        await removeOperatorOperaton({
          variables: {
            operationId: id,
          },
        });
      }

      const updated = await updateOperationMutation({
        variables: {
          data: {
            id,
            operatorActive: false,
            goal: operation?.goalReference,
            completed: 0,
            totalCompleted: 0,
            remaining: operation.total,
            name: `${moduleIndex + 1}.${
              typeof operation?.position !== 'undefined' &&
              operation.position + 1
            }`,
          },
        },
      });

      setLoading(false);
      // refetch();
    } catch (err) {
      setLoading(false);
      console.error('error updating operation', err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo modificar la operación.',
      });
    }
  };

  const setReference = async (id: string) => {
    if (isPreview) return;
    try {
      const reference = getReferenceByOperationId(id);

      await removeOperationAsReference({
        variables: {
          operationId: reference.id,
        },
        fetchPolicy: 'no-cache',
      });

      await setOperationAsReference({
        variables: {
          operationId: id,
        },
        fetchPolicy: 'no-cache',
      });
      // refetch();
    } catch (err) {
      console.error('error updating reference', err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo modificar la operación.',
      });
    }
  };

  const onOperationUpdatedSuccess = () => {
    refreshQueries();
  };

  const onUpdateAccumulateSuccess = () => {
    refreshQueries();
  };

  const refreshQueries = async () => {
    try {
      if (operation && breaksRef.current) {
        const hourTz = moment(new Date())
          .tz(organization?.timezone as string)
          .format('H');
        const hourInt = parseInt(hourTz, 10);

        const currentBreak: any = breaksRef.current.find(
          (currentBreak) => currentBreak.startHour === hourInt,
        );

        const opQuery = await operationQuery({
          variables: {
            operationId: operation.id,
          },
          fetchPolicy: 'no-cache',
        });

        const op = opQuery.data?.operation;

        if (op) {
          setOperation(op as Operation);
          setOperationDetails(op as Operation, currentBreak);
          updateReference(op.id, {
            ...op,
            color: color,
            operation: {
              ...op,
              color: color,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo hacer query a la operación.',
      });
    }
  };

  const refreshOperation = async (showLoading = true) => {
    // if (isPreview) return;
    if (showLoading) setLoading(true);
    try {
      if (operation) {
        const hourTz = moment(new Date())
          .tz(organization?.timezone as string)
          .format('H');
        const hourInt = parseInt(hourTz, 10);
        const restTimes = await queryRestTime({
          variables: {
            moduleId: operation?.module?.id as string,
          },
        });
        const GOAL = operation?.goalReference;

        const currentBreak: any = restTimes.data?.restTimes.find(
          (currentBreak) => currentBreak.startHour === hourInt,
        );

        if (currentBreak) {
          const minutes = new Date().getMinutes();
          if (currentBreak) {
            let endMins = currentBreak.endMin;
            if (
              currentBreak.endHour === currentBreak.startHour + 1 &&
              currentBreak.endMin === 0
            ) {
              endMins = 59;
            }

            if (minutes >= currentBreak.startMin && minutes < endMins) {
              setIsOnBreak(true);
            } else {
              setIsOnBreak(false);
            }
          }
        }

        // si cambia de hora
        if (currentBreak && GOAL) {
          if (showLoading) setLoading(true);

          if (
            currentBreak.endHour === currentBreak.startHour + 1 &&
            currentBreak.endMin === 0
          ) {
            const totalMinutes = 60 - (60 - currentBreak.startMin);

            const newGoal = (totalMinutes * GOAL) / 60;

            const updatedOperation = await updateOperationMutation({
              variables: {
                data: {
                  id: operation?.id,
                  goal: Math.ceil(newGoal),
                  startedLate: false,
                  currentHour: hour,
                },
              },
              fetchPolicy: 'no-cache',
            });
            setOperation(updatedOperation.data?.updateOperation as Operation);
            setOperationDetails(
              updatedOperation.data?.updateOperation as Operation,
              currentBreak,
            );

            if (!updatedOperation) {
              // TODO: handle no updated operation
              setLoading(false);
            }

            setLoading(false);
          } else {
            const totalMinutes =
              60 - (currentBreak.endMin - currentBreak.startMin);

            const newGoal = (totalMinutes * GOAL) / 60;

            const updatedOperation = await updateOperationMutation({
              variables: {
                data: {
                  id: operation?.id,
                  goal: Math.ceil(newGoal),
                  startedLate: false,
                  currentHour: hour,
                },
              },
              fetchPolicy: 'no-cache',
            });
            setOperation(updatedOperation.data?.updateOperation as Operation);
            setOperationDetails(
              updatedOperation.data?.updateOperation as Operation,
              currentBreak,
            );

            setLoading(false);
            if (!updatedOperation) {
              // TODO: handle no updated operation
              setLoading(false);
            }
          }
        } else {
          if (showLoading) setLoading(true);
          const updatedOperation = await updateOperationMutation({
            variables: {
              data: {
                id: operation?.id as string,
                goal: operation?.goalReference,
                startedLate: false,
                currentHour: hour,
              },
            },
            fetchPolicy: 'no-cache',
          });

          setOperation(updatedOperation.data?.updateOperation as Operation);
          setOperationDetails(
            updatedOperation.data?.updateOperation as Operation,
            currentBreak,
          );
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo refrescar la operación.',
      });
    }
  };

  const onSelectOption = (selected) => {
    switch (selected) {
      case 'user_offline':
        operatorOffline(operation?.operator?.id, operation?.id);
        break;
      case 'edit':
        toggleOperationModal(true);
        break;
      case 'accum':
        toggleAccumulateModal(true);
        break;
      case 'history':
        toggleHistoryModal(true);
        break;
      case 'reference':
        if (reference) setReference(reference);
        break;
      case 'delete':
        toggleConfirmationModal(true);
        break;
      case 'refresh':
        refreshOperation();
        break;
      default:
        break;
    }
  };

  /**
   * SOCKET RESTIME UPDATED
   */
  const restimeUpdatedSocket = useRef<Socket>();
  useEffect(() => {
    const RESTIME_UPDATED = 'restTimeUpdated';
    restimeUpdatedSocket.current = io(CURRENT_API.SOCKET_IO, {
      ...SOCKET_IO_CONFIG,
    });

    restimeUpdatedSocket.current.on(
      `${RESTIME_UPDATED}/${moduleId}`,
      ({ restTimeUpdated }: { restTimeUpdated: RestTime[] }) => {
        if (restTimeUpdated.length) {
          const hourTz = moment(new Date())
            .tz(organization?.timezone as string)
            .format('H');
          const hourInt = parseInt(hourTz, 10);
          const minutes = new Date().getMinutes();
          const breaks = restTimeUpdated;
          if (breaks && !isPreview) {
            const currentBreak = breaks.find((b) => b.startHour === hourInt);
            if (currentBreak) {
              let endMins = currentBreak.endMin;
              if (
                currentBreak.endHour === currentBreak.startHour + 1 &&
                currentBreak.endMin === 0
              ) {
                endMins = 59;
              }

              if (minutes >= currentBreak.startMin && minutes < endMins) {
                const updateOperation = async () => {
                  await updateOperationMutation({
                    variables: {
                      data: {
                        id: operation?.id as string,
                        onBreak: true,
                      },
                    },
                    fetchPolicy: 'no-cache',
                  });
                };
                updateOperation();
              } else {
                const updateOperation = async () => {
                  await updateOperationMutation({
                    variables: {
                      data: {
                        id: operation?.id as string,
                        onBreak: true,
                      },
                    },
                    fetchPolicy: 'no-cache',
                  });
                };
                updateOperation();
              }
            }
          }
        }
      },
    );

    restimeUpdatedSocket.current.on('pong', () => {});
    return () => {
      restimeUpdatedSocket.current?.disconnect();
    };
  }, []);

  // /**
  //  * SOCKET OPERATOR ONLINE
  //  */
  // useEffect(() => {
  //   const CHECK_OPERATOR_CONNECTED_CHANNEL = `checkUserOnline`;
  //   const CHECK_OPERATOR_ID_CONNECTED_CHANNEL = `checkUserOnline/${operation?.operator?.id}/operator`;
  //   const CHECK_OPERATOR_OPERATION_CONNECTED_CHANNEL = `checkUserOnline/${operation?.operator?.id}/operation`;
  //   const OPERATOR_CONNECTED_CHANNEL = `operatorIsOnline/${operation?.operator?.id}`;
  //   const OPERATOR_DISCONNECTED_CHANNEL = `operatorIsOffline/${operation?.operator?.id}`;
  //   operatorOnlineSocket.current = io(CURRENT_API.SOCKET_IO, {
  //     ...SOCKET_IO_CONFIG,
  //     autoConnect: true,
  //   });

  //   operatorOnlineSocket.current.emit(
  //     CHECK_OPERATOR_CONNECTED_CHANNEL,
  //     operation?.operator?.id,
  //     (isConnected) => {
  //       if (isConnected) {
  //         setOperatorOnline(true);
  //       }
  //     },
  //   );

  //   operatorOnlineSocket.current.on(
  //     CHECK_OPERATOR_OPERATION_CONNECTED_CHANNEL,
  //     (value) => {
  //       if (value) {
  //         setOperatorOnline(true);
  //       }
  //     },
  //   );

  //   operatorOnlineSocket.current.on(OPERATOR_CONNECTED_CHANNEL, () => {
  //     setOperatorOnline(true);
  //   });

  //   operatorOnlineSocket.current.on(OPERATOR_DISCONNECTED_CHANNEL, () => {
  //     setOperatorOnline(false);
  //   });

  //   operatorOnlineSocket.current.on('pong', () => {});

  //   return () => {
  //     operatorOnlineSocket.current?.disconnect();
  //   };
  // }, []);

  /**
   * SOCKET UPDATED OPERATION
   */
  useEffect(() => {
    updatedOperationSocket.current = io(CURRENT_API.SOCKET_IO, {
      ...SOCKET_IO_CONFIG,
    });

    updatedOperationSocket.current.on(
      `${OPERATION_UPDATED}/${id}`,
      ({ operationUpdated }: { operationUpdated: Operation }) => {
        const op = operationUpdated;
        if (op) {
          const hourTz = moment(new Date())
            .tz(organization?.timezone as string)
            .format('H');
          const hourInt = parseInt(hourTz, 10);
          const currentBreak: RestTime | undefined = breaksRef.current?.find(
            (currentBreak) => currentBreak.startHour === hourInt,
          );

          setOperation({
            ...(op as Operation),
            operator: operationRef.current?.operator,
          });
          updateReference(op.id, {
            ...op,
            operator: operationRef.current?.operator,
            operation: {
              ...op,
              operator: operationRef.current?.operator,
            },
          });
          operationRef.current = {
            ...(op as Operation),
            operator: operationRef.current?.operator,
          };
          setOperationDetails(
            {
              ...(operation as any),
              operator: operationRef.current?.operator,
            },
            currentBreak,
          );
        }
      },
    );

    updatedOperationSocket.current.on('pong', () => {});

    return () => {
      updatedOperationSocket.current?.disconnect();
    };
  }, []);

  /**
   * SOCKET REGISTER EVENT
   */
  useEffect(() => {
    registerEventSocket.current = io(CURRENT_API.SOCKET_IO, {
      ...SOCKET_IO_CONFIG,
    });

    registerEventSocket.current.on(
      `${REGISTERED_EVENT}/${id}`,
      ({ registeredEvent: op }: { registeredEvent: Operation }) => {
        if (op) {
          const hourTz = moment(new Date())
            .tz(organization?.timezone as string)
            .format('H');
          const hourInt = parseInt(hourTz, 10);
          const currentBreak: RestTime | undefined = breaksRef.current?.find(
            (currentBreak) => currentBreak.startHour === hourInt,
          );

          setOperation({
            ...(op as Operation),
          });
          updateReference(op.id, {
            ...op,
            operation: {
              ...op,
            },
          });
          operationRef.current = op as Operation;
          setOperationDetails(
            {
              ...(operation as any),
            },
            currentBreak,
          );
        }
      },
    );

    registerEventSocket.current.on('pong', () => {});

    return () => {
      registerEventSocket.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (breaksRef.current && breaksRef.current.length > 0 && !isPreview) {
      const hourTz = moment(new Date())
        .tz(organization?.timezone as string)
        .format('H');
      const hourInt = parseInt(hourTz, 10);
      const minutes = new Date().getMinutes();
      const currentBreak = breaks.find((b) => b.startHour === hourInt);
      if (currentBreak) {
        let endMins = currentBreak.endMin;
        if (
          currentBreak.endHour === currentBreak.startHour + 1 &&
          currentBreak.endMin === 0
        ) {
          endMins = 59;
        }

        if (minutes >= currentBreak.startMin && minutes < endMins) {
          setIsOnBreak(true);
        } else {
          setIsOnBreak(false);
        }
      }
    }
  }, [time, isPreview]);

  useEffect(() => {
    if (color && !colorRef.current) {
      colorRef.current = color;
    }

    if (color && colorRef.current && operation?.id) {
      if (color !== colorRef.current) {
        colorRef.current = color;

        updateReference(operation.id, {
          ...operation,
          color: color,
          operation: {
            ...operation,
            color: color,
          },
        });
      }
    }
  }, [color, colorRef.current, operation]);

  useEffect(() => {
    breaksRef.current = breaks as RestTime[];
  }, [breaks]);

  useEffect(() => {
    if (typeof operationData !== 'undefined' && operationData.goal) {
      setOperation(operationData as OperationType);
      operationRef.current = operationData;
    }
  }, [operationData]);

  useEffect(() => {
    if (operation && breaks) {
      const hourTz = moment(new Date())
        .tz(organization?.timezone as string)
        .format('H');
      const hourInt = parseInt(hourTz, 10);
      const currentBreak = breaks?.find(
        (currentBreak) => currentBreak.startHour === hourInt,
      );
      setOperationDetails(
        {
          ...(operation as any),
        },
        currentBreak,
      );
    }
  }, [operation, breaks]);

  useEffect(() => {
    const updateInterval = setInterval(() => {
      refreshQueries();
    }, 30000);
    return () => {
      clearInterval(updateInterval);
    };
  }, []);

  /*
    Initialize drag and drop into the element using its reference.
    Here we initialize both drag and drop on the same element (i.e., Image component)
  */
  drag(drop(ref));

  const TOTAL_COMPLETED = (operation?.totalCompleted as number) ?? 0;
  const TOTAL = operation?.total ?? (total as number);
  const GOAL = operation?.goal as number;
  const GOAL_TOTAL = operation?.goalReference as number;
  const REMAINING = (TOTAL as number) - TOTAL_COMPLETED;

  useEffect(() => {
    if (operation?.goal) {
      if (isNaN(Math.ceil(operation.goal))) {
        window.location.reload();
      }
    }
  }, [operation]);

  const colors = {
    '#9ca3af': 'bg-gray-400',
    YELLOW: '#FFFF00',
    RED: '#ff0000',
    GREEN: '#00FF00',
  };

  // const isOperatorOnline = operation?.operatorActive
  //   ? operation.operatorActive
  //     ? 'Conectando'
  //     : operatorOnline
  //     ? 'Online'
  //     : 'Offline'
  //   : 'Offline';

  // const operatorStatusColor = operation?.operatorActive
  //   ? operation.operatorActive
  //     ? ''
  //     : operatorOnline
  // ? 'text-green-400'
  // : 'text-red-500'
  //   : 'text-red-500';

  return (
    <>
      <div id={id} ref={ref} data-handler-id={handlerId} className="relative">
        {loading && (
          <div className="absolute top-0 left-0 z-40 flex h-full w-full items-center justify-center bg-white bg-opacity-90">
            <Loading size={35} />
          </div>
        )}
        <Card sx={{ maxWidth: 345, overflow: 'initial' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#ebebeb', color: 'black' }}>
                {`${moduleIndex + 1}.${
                  typeof operation?.position !== 'undefined' &&
                  operation.position + 1
                }`}
              </Avatar>
            }
            action={
              !isPreview ? (
                <Dropdown
                  onSelect={onSelectOption}
                  options={getOptions(Boolean(operation?.operator?.id))}
                />
              ) : null
            }
            title={
              <p className="truncate">
                {!operation?.operator?.id
                  ? 'Sin asignar'
                  : operation?.operator.name}
              </p>
            }
            subheader={
              <p className="truncate">
                {!operation?.name ? 'Sin nombre' : operation?.name}
              </p>
            }
          />
          <div className="flex w-full justify-center">
            <div
              className={cn(
                'my-[8px] flex h-[100px] w-[100px] items-center justify-center rounded-full',
              )}
              style={{
                backgroundColor:
                  operation?.operatorActive && !isOnBreak ? color : '#9ca3af',
              }}>
              <p className="text-4xl">{operation?.completed}</p>
            </div>
          </div>
          <CardContent>
            <div className="flex">
              <Typography
                variant="body2"
                className={cn(
                  operation.operatorActive ? 'text-green-400' : 'text-red-500',
                  'mr-2',
                )}>
                {operation.operatorActive ? 'Online' : 'Offline'}
              </Typography>
              <div className="ml-2">
                <Typography variant="body2">
                  {isOnBreak ? 'En descanso' : ''}
                </Typography>
              </div>
            </div>

            <Typography variant="body2" color="text.secondary">
              <strong>META:</strong> {Math.round(GOAL)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>ACUMULADO:</strong> {TOTAL_COMPLETED}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>SALDO:</strong> {REMAINING}
            </Typography>
          </CardContent>
        </Card>
        {REMAINING < 60 && (
          <div className="absolute -right-[15px] -top-[15px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-red-500 text-white">
            <p>{REMAINING <= 0 ? 0 : REMAINING}</p>
          </div>
        )}
      </div>
      {operation && (
        <OperationModal
          id={operation?.id as string}
          operator={operation?.operator as Operator}
          isOpen={isOperationModalOpen}
          closeModal={() => toggleOperationModal(false)}
          onSuccess={onOperationUpdatedSuccess}
          goal={GOAL_TOTAL as number}
          name={
            operation?.name
              ? (operation?.name as string)
              : `${name}.${index + 1}`
          }
          // TODO: change type
          operation={operation as any}
        />
      )}

      <AccumulateModal
        // TODO: change type
        total={(operation?.total as any) ?? total}
        completed={operation?.totalCompleted?.toString() as string}
        id={operation?.id as string}
        isOpen={isAccumulateModalOpen}
        onSuccess={onUpdateAccumulateSuccess}
        closeModal={() => toggleAccumulateModal(false)}
      />
      <HistoryModal
        id={operation?.id as string}
        isOpen={isHistoryModalOpen}
        closeModal={() => toggleHistoryModal(false)}
        goal={operation?.goalReference as number}
      />

      <CleanOperationConfirmModal
        isOpen={confirmationModal}
        onSuccess={() => cleanOperation(operation?.id as string)}
        closeModal={() => toggleConfirmationModal(false)}
      />
    </>
  );
};

export default React.memo(OperationComponent);
