/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import { Operation, RestTime } from '@/generated/graphql';

const HOUR_MINUTES = 60;
const YELLOW = 0.05;
const RED = 0.07;

let interval;

export const useProductivityColor = () => {
  const [operation, setOperation] = useState<Operation>();
  const [color, setColor] = useState('GREEN');
  const [currentBreak, setCurrentBreak] = useState<RestTime | undefined>();
  const colorRef = useRef(null);
  const operationRef = useRef<Operation | null>();

  const setOperationDetails = (operation: Operation, hasBreak) => {
    setOperation(operation);
    setCurrentBreak(hasBreak);
  };

  const getOperationColor = () => {
    const completed = (operationRef.current?.completed as number) ?? 0;

    let totalMinutes = 60;
    let currentMinute = new Date().getMinutes();
    if (currentBreak) {
      if (
        currentBreak.endHour === currentBreak.startHour + 1 &&
        currentBreak.endMin === 0
      ) {
        totalMinutes = 60 - (60 - currentBreak.startMin);
      } else {
        totalMinutes = 60 - (currentBreak.endMin - currentBreak.startMin);
        if (currentBreak.endMin <= currentMinute) {
          const diff = currentBreak.endMin - currentBreak.startMin;
          currentMinute = currentMinute - diff;
        }
      }
    }
    const perMinute = (operationRef.current?.goal as number) / totalMinutes;
    // const loggedAt = operationRef.current?.startedAtMinute as number;
    // let startedAt = operationRef.current?.startedAtMinute as number;

    const startedLate = operationRef.current?.startedLate as boolean;

    if (!startedLate) {
      // startedAt = 0;
    } else {
      // startedAt = loggedAt;
    }

    const getColor = () => {
      const currentGoal = operationRef.current?.goal as number;
      const shouldHaveCompletedByNow = Math.ceil(
        (currentMinute * currentGoal) / totalMinutes,
      );

      const getTotal = (percentage) => {
        const tot = shouldHaveCompletedByNow * percentage;
        return shouldHaveCompletedByNow - tot;
      };

      const yellow = Math.floor(getTotal(YELLOW));
      const red = Math.floor(getTotal(RED) - 1);

      if (completed >= shouldHaveCompletedByNow) return 'GREEN';

      if (completed < yellow) {
        return 'RED';
      } else if (
        (completed < yellow && completed > red) ||
        completed <= shouldHaveCompletedByNow
      ) {
        return 'YELLOW';
      }
      return 'GRAY';
    };

    const color = getColor();
    return color;
  };

  const calculateColor = async () => {
    const colors = {
      GRAY: '#9ca3af',
      YELLOW: '#FFFF00',
      RED: '#ff0000',
      GREEN: '#00FF00',
    };
    const currentColor = getOperationColor();
    const color = colors[currentColor as any];
    // if (color !== colorRef.current) {
    //   colorRef.current = color;
    //   if (operationRef.current) {
    //     const updateOperation = await updateDocument(
    //       "operations",
    //       operation?.id as string,
    //       {
    //         color,
    //       }
    //     );
    //   }
    // }

    setColor(color);
  };

  useEffect(() => {
    operationRef.current = operation;
    const minutes = new Date().getMinutes();
    if (operation && operationRef.current && typeof interval === 'undefined') {
      // setStarted(true);

      let isOnBreak = false;

      if (currentBreak) {
        let endMins = currentBreak.endMin;
        if (
          currentBreak.endHour === currentBreak.startHour + 1 &&
          currentBreak.endMin === 0
        ) {
          endMins = 59;
        }

        if (minutes >= currentBreak.startMin && minutes < endMins) {
          isOnBreak = true;
        } else {
          isOnBreak = false;
        }
      }

      if (!isOnBreak) {
        calculateColor();
      }
    }
  }, [operation, calculateColor, currentBreak]);

  return {
    color,
    setOperationDetails,
    calculateColor,
  };
};
