import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { Button } from '@/components/Elements';

type CleanOperationConfirmModalProps = {
  isOpen: boolean;

  closeModal: () => void;
  onSuccess: () => void;
};

export const CleanOperationConfirmModal: React.FC<
  CleanOperationConfirmModalProps
> = ({ isOpen = false, closeModal, onSuccess }) => {
  const handleSuccess = () => {
    onSuccess();
    closeModal();
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Limpiar operación
                </Dialog.Title>
                <div className="mt-2">
                  <p>¿Estás seguro que quieres limpiar la operación?</p>
                  <div className="mt-4">
                    <Button
                      type="button"
                      onClick={() => handleSuccess()}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                      Limpiar operación
                    </Button>
                  </div>
                  <div className="mt-4">
                    <Button
                      type="button"
                      onClick={closeModal}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
                      Cerrar
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
