import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { memo, useEffect, useRef, useState } from 'react';

import { Table } from '@/components/Elements';
import { useFirebase } from '@/lib/firebase';
import { useUserStore } from '@/stores/user';
import { Phone } from '@/types/API';

export type PhonesTableProps = {
  name?: string;
  total?: number;
};

const PhonesListComponent: React.FC<PhonesTableProps> = ({ total }) => {
  const { db, getDocuments } = useFirebase();

  const [phones, setPhones] = useState<Phone[]>([]);
  const phonesRef = useRef<Phone[] | null>(null);
  const { user } = useUserStore();

  useEffect(() => {
    let unsubscribe;
    if (user?.organizationId && phones.length === 0) {
      const q = query(
        collection(db, 'phones'),
        where('organizationId', '==', user?.organizationId),
      );
      unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setPhones((prev) => {
              const newPhones = [
                ...(prev as any),
                { ...change.doc.data() } as any,
              ];
              phonesRef.current = newPhones;
              return newPhones;
            });
          }
          if (change.type === 'modified') {
            const phone = change.doc.data();

            const updatedPhones = [...(phonesRef.current as Phone[])];

            const phoneIndex = updatedPhones.findIndex(
              (o) => o.id === phone.id,
            );

            if (phoneIndex > -1) {
              updatedPhones[phoneIndex] = phone as any;

              setPhones(() => [...updatedPhones]);
              phonesRef.current = [...updatedPhones];
            }
          }
        });
      });
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user]);

  const fetchPhones = async () => {
    try {
      const phonesArr = await getDocuments(
        'phones',
        'organizationId',
        user?.organizationId as string,
      );

      const p = phonesArr as Phone[];
      const totalPhones = total;
      const totalCurrent = (phones as Phone[]).length;

      const phonesArray = Array.from(new Array(totalPhones)).map((_, index) => {
        if (p[index]) {
          return {
            ...p[index],
            isActive: true,
          };
        }

        return {
          token: 'Not active',
          isActive: false,
        };
      });

      phonesRef.current = phonesArray as any;

      setPhones(phonesArray as any);
    } catch (err) {
      console.error('error getting module' + err);
    }
  };

  useEffect(() => {
    if (phones.length === 0) {
      fetchPhones();
    }
  }, [phones]);

  return (
    <>
      <div className="w-full sm:px-6 lg:px-8">
        <div className="overflow-auto">
          <Table<any>
            data={phones as Phone[]}
            height={'min-h-[500px] max-h-[500px]'}
            columns={[
              {
                title: 'Token',
                field: 'token',
              },
              {
                title: 'Activo',
                field: 'active',
                Cell({ entry: { active } }) {
                  return <p>{active ? 'Activo' : 'Inactivo'}</p>;
                },
              },
              {
                title: 'Creado',
                field: 'createdAt',
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export const PhonesList = memo(PhonesListComponent);
