import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import {
  RestTime,
  useCreateOperationMutation,
  useUpdateModuleMutation,
  useUpdateOperationsMutation,
} from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';
import { Operation } from '@/types/API';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  total: z.string().min(1, 'Required'),
  goal: z.string().min(1, 'Required'),
});

type ReferenceModalProps = {
  id?: string;
  goal?: number;
  name?: string;
  total?: number;
  module: string;
  isOpen: boolean;
  operations?: Operation[];
  totalOperations?: number;
  update?: boolean;
  breaks?: RestTime[];
  closeModal: () => void;
  onSuccess: () => void;
};

type ReferenceValues = {
  total: string;
  goal: string;
};

export const ReferenceModal: React.FC<ReferenceModalProps> = ({
  id,
  module,
  name,
  goal,
  total,
  isOpen = false,
  operations,
  totalOperations,
  update = false,
  breaks = [],
  closeModal,
  onSuccess,
}) => {
  const [createOperationMutation] = useCreateOperationMutation();
  const [updateOperations] = useUpdateOperationsMutation();
  const [updateModule] = useUpdateModuleMutation();
  const [loading, setLoading] = useState(false);

  function isNumeric(str: string) {
    if (typeof str != 'string') return false; // we only process strings!
    return (
      !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const createReference = async (values: ReferenceValues) => {
    setLoading(true);
    try {
      if (operations && totalOperations) {
        if (operations.length === 0 && totalOperations > 0) {
          const promises = Array.from(new Array(totalOperations)).map(
            (_, index) =>
              createOperationMutation({
                variables: {
                  data: {
                    total: parseInt(values.total, 10),
                    remaining: parseInt(values.total, 10),
                    goalReference: parseInt(values.goal, 10),
                    goal: parseInt(values.goal, 10),
                    moduleId: module,
                    isReference: index === 0,
                    color: 'GREEN',
                    completed: 0,
                    totalCompleted: 0,
                    currentHour: 0,
                    name: `${index + 1}`,
                    onBreak: false,
                    operatorActive: false,
                    startedLate: false,
                    position: index,
                  },
                },
              }),
          );

          Promise.all(promises)
            .then(async () => {
              setLoading(false);
              onSuccess();
              closeModal();

              useNotificationStore.getState().addNotification({
                type: 'success',
                title: 'Success',
                message: 'Referencia creada',
              });
            })
            .catch((err) => {
              console.error('error at createingModules: ', err);
              throw new Error('Could not create opretions');
            });
        }
      }
    } catch (err) {
      console.error('error creating reference: ' + err);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (err as Error).message,
      });
      setLoading(false);
    }
  };

  const updateReference = async (values: ReferenceValues) => {
    setLoading(true);

    try {
      if (isNumeric(values.goal) && isNumeric(values.total) && module) {
        const total = parseInt(values.total);
        const goal = parseInt(values.goal);

        const hour = new Date().getHours();
        const currentBreak: any = breaks.find(
          (currentBreak) => currentBreak.startHour === hour,
        );

        await updateModule({
          variables: {
            data: {
              id: module,
              goal: goal,
            },
          },
        });

        console.log({ currentBreak });
        if (currentBreak) {
          if (
            currentBreak.endHour === currentBreak.startHour + 1 &&
            currentBreak.endMin === 0
          ) {
            const totalMinutes = 60 - (60 - currentBreak.startMin);
            const newGoal = (totalMinutes * goal) / 60;
            await updateOperations({
              variables: {
                data: {
                  moduleId: module,
                  total,
                  goal: Math.ceil(newGoal),
                  goalReference: goal,
                },
              },
            });
          } else {
            const totalMinutes =
              60 - (currentBreak.endMin - currentBreak.startMin);
            const newGoal = (totalMinutes * goal) / 60;

            console.log({ newGoal });
            await updateOperations({
              variables: {
                data: {
                  moduleId: module,
                  total,
                  goal: Math.ceil(newGoal),
                  goalReference: goal,
                },
              },
            });
          }
        } else {
          await updateOperations({
            variables: {
              data: {
                moduleId: module,
                total,
                goal,
                goalReference: goal,
              },
            },
          });
        }

        onSuccess();
        closeModal();

        setLoading(false);
        useNotificationStore.getState().addNotification({
          type: 'success',
          title: 'Success',
          message: 'Operaciones actualizadas',
        });
      }
    } catch (err) {
      console.error('error updating reference: ' + err);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (err as Error).message,
      });
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  {!update ? 'Crear referencia' : 'Editar referencia'}
                </Dialog.Title>
                <div className="mt-2">
                  <Form<ReferenceValues, typeof schema>
                    onSubmit={async (values) => {
                      console.log({ update, values });
                      if (!update) createReference(values);
                      if (update) updateReference(values);
                    }}
                    schema={schema}>
                    {({ register, formState, getValues }) => (
                      <>
                        <InputField
                          type="text"
                          label="Total"
                          error={formState.errors['total']}
                          registration={register('total')}
                          value={total}
                        />
                        <InputField
                          type="text"
                          label="Meta"
                          error={formState.errors['goal']}
                          registration={register('goal')}
                          value={goal}
                        />
                        <div>
                          <Button
                            isLoading={loading}
                            disabled={
                              loading ||
                              getValues().goal?.length === 0 ||
                              getValues().total?.length === 0
                            }
                            onClick={() => {
                              if (!update) createReference(getValues());
                              if (update) updateReference(getValues());
                            }}
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                            {!update ? 'Crear' : 'Editar'}
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
