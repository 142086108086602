import create from 'zustand';

export type UIsStore = {
  showActive: boolean;
  toggleActive: () => void;
};

export const useUIStore = create<UIsStore>((set) => ({
  showActive: true,
  toggleActive: () =>
    set((state) => ({
      showActive: !state.showActive,
    })),
}));
