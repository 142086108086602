import { memo, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { Dashboard } from '@/features/misc';
import { lazyImport } from '@/utils/lazyImport';

const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { Phones } = lazyImport(() => import('@/features/users'), 'Phones');
const { Sessions } = lazyImport(() => import('@/features/users'), 'Sessions');
const { OnboardingRoutes } = lazyImport(
  () => import('@/features/onboarding'),
  'OnboardingRoutes',
);
const { PrivacyPolicy } = lazyImport(
  () => import('@/features/misc'),
  'PrivacyPolicy',
);
const { NewUser } = lazyImport(() => import('@/features/users'), 'NewUser');

const AppComponent = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const App = memo(AppComponent);

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'users', element: <Users /> },
      { path: 'phones', element: <Phones /> },
      { path: 'sessions', element: <Sessions /> },
      { path: 'profile', element: <Profile /> },
      { path: '', element: <Dashboard /> },
      { path: 'preview', element: <Dashboard isPreview={true} /> },
      { path: '*', element: <Navigate to="." /> },
      { path: 'onboarding/*', element: <OnboardingRoutes /> },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'new-user',
        element: <NewUser />,
      },
    ],
  },
];
