import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { CURRENT_API } from '@/client';
import { useClient } from '@/hooks/useClient';
import { useNotificationStore } from '@/stores/notifications';

export const useGetModuleById = ({ id }) => {
  const client = useClient({
    url: CURRENT_API.API_SINGLE,
  });

  const fn = async () => {
    const response = await client.getModuleById(id);
    return response;
  };

  const onError = useCallback((error: AxiosError) => {
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message: error.message,
    });
  }, []);

  const query = useQuery(['module-by-id', id], fn, {
    enabled: Boolean(id),
    onError,
  });

  return { ...query };
};
