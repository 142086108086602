// @ts-nocheck
import { ArchiveIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import * as React from 'react';
import { useDrag } from 'react-dnd';

import { ItemTypes } from '@/features/misc';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  onDrop?: (data: any) => void;
  shoudlDrag?: boolean;
  height?: string | null;
};

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  onDrop,
  shoudlDrag = false,
  height = null,
}: TableProps<Entry>) => {
  if (!data?.length) {
    return (
      <div className="flex h-80 flex-col items-center justify-center bg-white text-gray-500">
        <ArchiveIcon className="h-16 w-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <div className=" w-full overflow-x-auto ">
        <div className="inline-block min-w-full py-2 align-middle ">
          <div
            className={cn(
              ' overflow-auto border-b border-gray-200 shadow sm:rounded-lg',
              height ? height : '',
            )}>
            <table className="min-w-full table-auto divide-y divide-gray-200 overflow-scroll">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="overflow-y-scroll">
                {shoudlDrag && (
                  <>
                    {data.map((entry, entryIndex) => (
                      <Tr
                        {...entry}
                        key={entry?.id || entryIndex}
                        entryIndex={entryIndex}
                        onDrop={onDrop}>
                        {columns.map(({ Cell, field, title }, columnIndex) => (
                          <td
                            key={title + columnIndex}
                            className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                            {Cell ? <Cell entry={entry} /> : entry[field]}
                          </td>
                        ))}
                      </Tr>
                    ))}
                  </>
                )}
                {!shoudlDrag && (
                  <>
                    {data.map((entry, entryIndex) => (
                      <tr
                        {...entry}
                        key={entry?.id || entryIndex}
                        onDrop={onDrop}>
                        {columns.map(({ Cell, field, title }, columnIndex) => (
                          <td
                            key={title + columnIndex}
                            className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                            {Cell ? <Cell entry={entry} /> : entry[field]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}{' '}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tr: React.FC<{
  children: React.ReactNode;
  entryIndex: number;
  onDrop: (item: any) => void;
}> = ({ entryIndex, children, onDrop, ...rest }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.OPERATOR,
    item: { ...rest },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<any>();
      if (item && dropResult) onDrop({ ...dropResult, ...item });
    },
  }));

  return (
    <tr
      ref={drag}
      className={entryIndex % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
      {children}
    </tr>
  );
};
