import create from 'zustand';

import { Operation } from '@/types/API';

export type Reference = {
  id: string;
  goal: number;
  name: string;
  moduleId: string;
  operationId: string;
  operation: Operation;
};

type ReferenceStore = {
  references: Reference[];
  setReferences: (references: Reference[]) => void;
  getReferenceById: (id: string) => any;
  getReferenceByOperationId: (id: string) => any;
  addReference: (reference: any) => any;
  updateReference: (id: any, data: any) => void;
  getReferenceByModuleId: (moduleId: string) => any;
};

export const useReferenceStore = create<ReferenceStore>((set, get: any) => ({
  references: [],
  setReferences: (references) =>
    set(() => ({
      references: references,
    })),
  getReferenceByOperationId: (id) => {
    const references = get().references;
    const foundReference = references.find(
      (reference) => reference.operationId === id,
    );

    return foundReference;
  },
  getReferenceById: (id) => {
    const references = get().references;
    const foundReference = references.find((reference) => reference.id === id);

    return foundReference;
  },
  getReferenceByModuleId: (moduleId) => {
    const references = get().references;
    const foundReference = references.find(
      (reference) => reference.moduleId === moduleId,
    );

    return foundReference;
  },
  addReference: (reference: any) => {
    const allReferences = get().references;

    const referenceExists = allReferences.find(
      (ref) => ref.id === reference.id,
    );

    if (referenceExists) {
      return false;
    } else {
      set(() => ({
        references: [...allReferences, reference],
      }));
    }

    return get().references;
  },
  updateReference: (id: any, data: any) => {
    const allReferences = get().references;

    const referenceIndex = allReferences.findIndex((ref) => ref.id === id);

    if (referenceIndex > -1) {
      const newReferences = [...allReferences];
      newReferences[referenceIndex] = {
        ...newReferences[referenceIndex],
        ...data,
      };
      set(() => ({
        references: [...newReferences],
      }));
    } else {
      return false;
    }

    return get().references;
  },
}));
