import cn from 'classnames';
import React from 'react';

const panels = [
  {
    id: '1',
    key: 'tabs-operators-tab',
    name: 'Operadores',
    target: 'tabs-operators',
  },
  {
    id: '2',
    key: 'tabs-devices-tab',
    name: 'Dispositivos',
    target: 'tabs-devices',
  },
];

type LabelProps = {
  id: string;
  keyName: string;
  target: string;
  title: string;
  isActive: boolean;
  setOpened: (id: string) => void;
  toggleDrawer: () => void;
};

const Label: React.FC<LabelProps> = ({
  id,
  keyName,
  target,
  title,
  isActive = false,
  setOpened,
  toggleDrawer,
}) => {
  return (
    <li className="nav-item" role="presentation">
      <a
        href={`#${target}`}
        className={cn(
          isActive ? 'active' : '',
          'nav-link my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 text-xs font-medium uppercase leading-tight hover:border-transparent hover:bg-gray-100 focus:border-transparent',
        )}
        id={keyName}
        data-bs-toggle="pill"
        data-bs-target={`#${target}`}
        role="tab"
        aria-controls={target}
        aria-selected={isActive ? 'true' : 'false'}
        onClick={() => {
          setOpened(id);
          toggleDrawer();
        }}>
        {title}
      </a>
    </li>
  );
};

export type TabsProps = {
  toggleDrawer: () => void;
  children: React.ReactNode;
  opened: string;
  setOpened: any;
};

export const Tabs: React.FC<TabsProps> = ({
  toggleDrawer,
  children,
  opened,
  setOpened,
}) => {
  const handleOpened = (id: string) => {
    setOpened(id);
  };
  return (
    <div className="mt-2 flex w-full flex-col items-center justify-center">
      <ul
        className="nav nav-tabs mb-4 flex  list-none flex-row flex-wrap border-b-0 pl-0 md:w-auto "
        id="tabs-tab"
        role="tablist">
        {panels.map((panel) => (
          <Label
            id={panel.id}
            key={panel.id}
            keyName={panel.key}
            target={panel.target}
            title={panel.name}
            isActive={opened === panel.id}
            setOpened={handleOpened}
            toggleDrawer={toggleDrawer}
          />
        ))}
      </ul>
      <div className="tab-content w-full" id="tabs-tabContent">
        {children}
      </div>
    </div>
  );
};
