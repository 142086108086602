import { PencilIcon } from '@heroicons/react/outline';
import { Avatar, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import type { Identifier } from 'dnd-core';
import { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import { Dropdown } from '@/components/Elements';
import { Loading } from '@/components/Loading';
import {
  Operation,
  RestTime,
  useRemoveOperationAsReferenceMutation,
  useSetOperationAsReferenceMutation,
} from '@/generated/graphql';
import { useDate } from '@/hooks/useDate';
import { useProductivityColor } from '@/hooks/useProductivityColor';
import { useNotificationStore } from '@/stores/notifications';
import { useReferenceStore } from '@/stores/reference';
import { Reference as ReferenceType } from '@/types/API';

import { ItemTypes } from '../constants';
import { CleanOperationConfirmModal } from './CleanOperationConfirmModal';
import { ModuleNameMoal } from './ModuleNameModal';
import { ReferenceModal } from './ReferenceModal';

export type ReferenceProps = {
  className?: string;
  id: string;
  breaks: RestTime[];
  reference: ReferenceType;
  isPreview: boolean;
  module: string;
  onSuccess: () => void;
  moduleGoal: number;
  moduleName: string;
  onSuccessModuleName: () => void;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const getOptions = (hasOperation) => {
  const options = [
    {
      id: 'edit',
      label: 'Editar',
      icon: <PencilIcon width={20} height={20} />,
    },
    {
      id: 'name',
      label: 'Cambiar nombre',
      icon: <PencilIcon width={20} height={20} />,
    },

    // {
    //   id: 'delete',
    //   label: 'Limpiar operaciones',
    //   icon: <TrashIcon width={20} height={20} />,
    // },
  ];

  // if (hasOperation) {
  //   options.push({
  //     id: 'removeReference',
  //     label: 'Quitar operación',
  //     icon: <TrashIcon width={20} height={20} />,
  //   });
  // }

  return options;
};

export const Reference: React.FC<ReferenceProps> = ({
  id,
  breaks,
  reference: referenceData,
  isPreview = false,
  module,
  moduleName,
  onSuccess,
  onSuccessModuleName,
  moduleGoal,
}) => {
  const [nameModal, setNameModal] = useState(false);
  const [isReferenceModalOpen, toggleReferenceModal] = useState(false);
  const [reference, setReference] = useState<ReferenceType>();
  const operationIdRef = useRef<string | null>(null);
  const { getReferenceById, references, updateReference } = useReferenceStore();
  const [operation, setOperation] = useState<Operation>();
  const [loading, setLoading] = useState(false);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [confirmationModal, toggleConfirmationModal] = useState(false);
  const breaksRef = useRef<RestTime[] | null>();
  const { time, hour } = useDate();

  const [setOperationAsReference] = useSetOperationAsReferenceMutation();
  const [removeOperationAsReference] = useRemoveOperationAsReferenceMutation();

  const { color, setOperationDetails } = useProductivityColor();

  const [{ handlerId, isOver }, drop] = useDrop<
    DragItem,
    void,
    {
      handlerId: Identifier | null;
      isOver: boolean | null;
      canDrop: boolean | null;
    }
  >({
    accept: ItemTypes.OPERATION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item) {
      if (!isPreview) updateReferenceOnDrop(id, item as any);
    },
  });

  const fetchReference = async () => {
    try {
      // TODO: fetch operation reference
      // const document = await getSingleDocument(
      //   'references',
      //   'id',
      //   id as string,
      // );
      // if (document && (document as any)?.operationId) {
      //   operationIdRef.current = document?.operationId;
      // }
      // setReference(document as ReferenceType);
    } catch (err) {
      console.error('error getting reference' + err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo obtener la operación.',
      });
    }
  };

  useEffect(() => {
    if (typeof referenceData === 'undefined') {
      fetchReference();
    } else {
      operationIdRef.current = referenceData?.operationId as string;
      const op = getReferenceById(id);
      setOperation(op.operation);
      setReference(op);

      const hour = new Date().getHours();
      const currentBreak: RestTime | undefined = breaks?.find(
        (currentBreak) => currentBreak.startHour === hour,
      );
      setOperationDetails(
        {
          ...(op.operation as any),
        },
        currentBreak,
      );
    }
  }, [referenceData, references, breaks]);

  const updateReferenceOnDrop = async (
    newOperationId: string,
    operation: Operation | null,
    onDelete = false,
  ) => {
    if (onDelete && typeof operation === 'undefined') return;

    setLoading(true);
    try {
      await removeOperationAsReference({
        variables: {
          operationId: id,
        },
      });

      await setOperationAsReference({
        variables: {
          operationId: operation?.id as string,
        },
      });

      operationIdRef.current = (operation?.id as string) ?? null;

      const ref = getReferenceById(id);
      updateReference(id, { ...ref, ...reference, operation });
      setReference(ref);
      setLoading(false);
    } catch (err) {
      console.error('error updating tareference', err);
      setLoading(false);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo modificar la operación.',
      });
    }
  };

  useEffect(() => {
    if (breaks && breaks.length > 0 && !isPreview) {
      const hour = new Date().getHours();
      const minutes = new Date().getMinutes();
      const currentBreak = breaks.find((b) => b.startHour === hour);
      if (currentBreak) {
        let endMins = currentBreak.endMin;
        if (
          currentBreak.endHour === currentBreak.startHour + 1 &&
          currentBreak.endMin === 0
        ) {
          endMins = 59;
        }

        if (minutes >= currentBreak.startMin && minutes < endMins) {
          setIsOnBreak(true);
        } else {
          setIsOnBreak(false);
        }
      }
    }
  }, [time, isPreview]);

  const onReferenceUpdatedSuccess = () => {
    onSuccess();
  };

  const onSelectOption = (selected) => {
    switch (selected) {
      case 'removeReference':
        updateReferenceOnDrop(reference?.id as string, null, true);
        break;
      case 'edit':
        toggleReferenceModal(true);
        break;
      case 'reference':
        if (reference) setReference(reference);
        break;
      case 'name':
        setNameModal(true);
        break;
      default:
        break;
    }
  };

  const cleanOperation = async (id: string) => {
    if (isPreview) return;
  };
  const COMPLETED = operation?.completed ?? 0;
  const TOTAL_COMPLETED = (operation?.totalCompleted as number) ?? 0;
  const TOTAL = operation?.total ?? (reference?.total as number);
  const GOAL = operation?.goal as number;
  const CURRENT_GOAL = reference?.goal as number;
  const REMAINING = operation?.remaining as number;

  const colors = {
    '#9ca3af': 'bg-gray-400',
    YELLOW: '#FFFF00',
    RED: '#ff0000',
    GREEN: '#00FF00',
  };

  const prodColor = colors[operation?.color as string];

  return (
    <div
      id={id}
      ref={drop}
      data-handler-id={handlerId}
      className={cn('relative w-[230px]')}>
      {loading && (
        <div className="absolute top-0 left-0 z-40 flex h-full w-full items-center justify-center bg-white bg-opacity-90">
          <Loading size={35} />
        </div>
      )}
      <Card sx={{ maxWidth: 345, overflow: 'initial' }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#ebebeb', color: 'black' }}>T</Avatar>
          }
          action={
            !isPreview ? (
              <Dropdown
                onSelect={onSelectOption}
                options={getOptions(Boolean(operation?.operator?.id))}
              />
            ) : null
          }
          title={
            <p className="truncate">
              {operation?.operator?.id === null
                ? 'sin asignar'
                : operation?.operator?.name}
            </p>
          }
          subheader={<>{moduleName}</>}
        />
        <div className="flex w-full justify-center">
          <div
            className={cn(
              'my-[8px] flex h-[100px] w-[100px] items-center justify-center rounded-full',
            )}
            style={{
              backgroundColor:
                operation?.operatorActive && !isOnBreak ? color : '#9ca3af',
            }}>
            <p className="text-4xl">{operation?.completed}</p>
          </div>
        </div>
        <CardContent>
          <div className="flex">
            <Typography
              variant="body2"
              className={cn(
                operation?.operatorActive ? 'text-green-400' : 'text-red-500',
              )}>
              {operation?.operatorActive ? 'Online' : 'Offline'}
            </Typography>
            <div className="ml-2">
              <Typography variant="body2">
                {isOnBreak ? 'En descanso' : ''}
              </Typography>
            </div>
          </div>

          <Typography variant="body2" color="text.secondary">
            <strong>META TABLERO:</strong> {Math.round(moduleGoal)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>META OPERACIÓN:</strong> {Math.round(GOAL)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>ACUMULADO:</strong> {TOTAL_COMPLETED}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>SALDO:</strong> {(TOTAL as number) - TOTAL_COMPLETED}
          </Typography>
        </CardContent>
      </Card>

      <ReferenceModal
        module={module}
        id={operation?.id as string}
        isOpen={isReferenceModalOpen}
        closeModal={() => toggleReferenceModal(false)}
        onSuccess={onReferenceUpdatedSuccess}
        goal={moduleGoal}
        total={operation?.total as number}
        name={operation?.name as string}
        breaks={breaks}
        update
      />

      <CleanOperationConfirmModal
        isOpen={confirmationModal}
        onSuccess={() => cleanOperation(operation?.id as string)}
        closeModal={() => toggleConfirmationModal(false)}
      />

      <ModuleNameMoal
        onSuccess={onSuccessModuleName}
        name={moduleName}
        id={module}
        closeModal={() => setNameModal(false)}
        isOpen={nameModal}
      />
    </div>
  );
};
