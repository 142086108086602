import { useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useUpdateModuleMutation } from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
  totalOperations: z.string().min(1, 'Min 1').max(2, 'Max 99'),
});

type CreateModuleFormValues = {
  totalOperations: string;
};

type CreateModuleFormProps = {
  onSuccess: () => void;
  module: string;
  organizationId?: string;
};

export const CreateModuleForm = ({
  module,
  onSuccess,
}: CreateModuleFormProps) => {
  const [updateModule] = useUpdateModuleMutation();
  const [loading, setLoading] = useState(false);

  const handleCreateModule = async (values: CreateModuleFormValues) => {
    setLoading(true);
    try {
      const updatedModule = await updateModule({
        variables: {
          data: {
            id: module,
            totalOperations: parseInt(values.totalOperations, 10),
            goal: 60,
          },
        },
      });

      // const updatedModule = await updateDocument('modules', module, {
      //   totalOperations: parseInt(values.totalOperations, 10),
      // });

      if (updatedModule) {
        onSuccess();
        setLoading(false);
      }

      return updatedModule;
    } catch (err) {
      console.log(err);
      setLoading(false);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (err as Error).message,
      });
      return false;
    }
  };

  return (
    <div>
      <Form<CreateModuleFormValues, typeof schema>
        onSubmit={async (values) => {
          handleCreateModule(values);
        }}
        schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Cuántos puestos hay?"
              error={formState.errors['totalOperations']}
              registration={register('totalOperations')}
            />
            <div>
              <Button isLoading={loading} type="submit" className="w-full">
                Crear móulo
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
