export type EmptyOperationProps = {
  className?: string;
  name: string;
  index: number;
};
export const EmptyOperation: React.FC<EmptyOperationProps> = ({
  name,
  index,
}) => {
  return (
    <div className="my-2 mb-4 w-1/6  overflow-hidden px-1 sm:my-1 sm:w-1/4 sm:px-1 md:w-1/5 lg:w-1/5 xl:my-2 xl:w-1/5 xl:px-1">
      <div className="m-auto h-[250px] w-[100%] rounded-lg border border-black">
        Puesto {name}.{index + 1}
      </div>
    </div>
  );
};
