import { useMemo } from 'react';

import { CURRENT_API } from '@/client';
import { FluentaClient } from '@/lib/client';

export const tokenName = 'tokenV3Cincel';

export function useClient({ url = CURRENT_API.API_SINGLE }) {
  const newToken = localStorage.getItem(tokenName) as string;
  const client = useMemo(
    () =>
      new FluentaClient({
        baseURL: url as string,
        token: newToken as string,
      }),
    [newToken, url],
  );

  return client;
}
