import { useEffect, useState } from 'react';

import { Button, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useGetUsersQuery, User } from '@/generated/graphql';
import { formatDate } from '@/utils/format';

import { NewUserModal } from '../components/NewUserModal';

export const NewUser = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const handleNewUserSuccess = () => {
    fetchUsers();
  };

  const { data: usersData, refetch } = useGetUsersQuery();

  const fetchUsers = async () => {
    refetch();
  };

  useEffect(() => {
    if (usersData?.getUsers) {
      setUsers(usersData?.getUsers as User[]);
    }
  }, [usersData]);

  console.log({ usersData });

  return (
    <ContentLayout title="Usuarios">
      <div>
        <Button
          isLoading={false}
          onClick={() => toggleModal(true)}
          className="inline-flex  justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
          Crear usuario
        </Button>
      </div>
      <div className="overflow-auto">
        <Table<any>
          data={users as User[]}
          height={'h-[350px]'}
          columns={[
            {
              title: 'Name',
              field: 'name',
              Cell({ entry: { firstname, lastname } }) {
                return (
                  <p>
                    {firstname} {lastname}
                  </p>
                );
              },
            },
            {
              title: 'Code',
              field: 'activationCode',
            },
            {
              title: 'Organización',
              field: 'organization.name',
              Cell({ entry: { organization } }) {
                return <p>{organization.name}</p>;
              },
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Rol',
              field: 'role',
            },
            {
              title: 'Creado',
              field: 'createdAt',
              Cell({ entry: { createdAt } }) {
                return <p>{formatDate(createdAt)}</p>;
              },
            },
          ]}
        />
      </div>
      <NewUserModal
        isOpen={isModalOpen}
        closeModal={() => toggleModal(false)}
        onSuccess={handleNewUserSuccess}
      />
    </ContentLayout>
  );
};
