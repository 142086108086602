interface LoadingProps {
  size: number;
}

export const Loading: React.FC<LoadingProps> = ({ size }) => {
  return (
    <div className="flex h-10 w-[50%] justify-center">
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="animate-spin">
        <div
          className="h-full w-full rounded-[50%] border-4
       border-t-blue-300 border-b-blue-300"></div>
      </div>
    </div>
  );
};
