import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import { Fragment, useState } from 'react';

import { Button } from '@/components/Elements';
import { Loading } from '@/components/Loading';
import { useHistoryOperationQuery } from '@/generated/graphql';

type HistoryModalProps = {
  isOpen: boolean;
  id: string;
  goal: number;
  closeModal: () => void;
};

export const HistoryModal: React.FC<HistoryModalProps> = ({
  isOpen = false,
  id,
  closeModal,
  goal = 60,
}) => {
  const [day, setDay] = useState(() => moment().format('MM/DD/YYYY'));
  const { data, loading, refetch } = useHistoryOperationQuery({
    variables: {
      operationId: id,
      day,
    },
    skip: !isOpen,
  });

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function getNextDay(date = new Date()) {
    const next = new Date(date.getTime());
    next.setDate(date.getDate() + 1);

    return next;
  }

  const handleDayBack = () => {
    const newDay = getPreviousDay(new Date(day));
    setDay(moment(newDay).format('MM/DD/YYYY'));
  };

  const handleNextDay = () => {
    const newDay = getNextDay(new Date(day));
    setDay(moment(newDay).format('MM/DD/YYYY'));
  };

  const getWorkdayText = (hour) => {
    return (
      <p>
        Desde <span className="font-semibold">{`${hour}:00`}</span> hasta{' '}
        <span className="font-semibold">{`${hour + 1}:00`}</span>
      </p>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        {loading && (
          <div className="absolute top-0 left-0 z-40 flex h-full w-full items-center justify-center bg-white bg-opacity-90">
            <Loading size={35} />
          </div>
        )}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full  max-w-[700px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="mb-3 flex items-center justify-center gap-3 text-center text-lg font-medium leading-6 text-gray-900">
                  <Button
                    isLoading={loading}
                    onClick={() => handleDayBack()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                    Dia anterior
                  </Button>
                  <p>Reporte del día {day}</p>
                  <Button
                    isLoading={loading}
                    onClick={() => handleNextDay()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                    Dia siguiente
                  </Button>
                </Dialog.Title>
                <div>
                  <Button
                    isLoading={loading}
                    onClick={() => refetch()}
                    className="inline-flex w-20  justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                    Refrescar
                  </Button>
                </div>
                <div className="mt-2">
                  <table className="min-w-full border border-gray-400">
                    <thead className="border-b border-gray-400">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-medium text-gray-900">
                          HORA
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-medium text-gray-900">
                          META
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-medium text-gray-900">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.historyOperation.map((history, index) => {
                        // const hour: any = history.hour;
                        // const total = history.total
                        // if (total === 0) return null;
                        // let currentGoal = history.goal;
                        // if (typeof .goal === 'undefined') {
                        //   if (hour?.startsAt === 0 && hour?.endsAt === 60) {
                        //     currentGoal = 60;
                        //   } else {
                        //     const totalMinutes = hour.endsAt - hour.startsAt;

                        //     const newGoal = (totalMinutes * goal) / 60;

                        //     currentGoal = newGoal;
                        //   }
                        // }

                        return (
                          <tr key={index} className="border-b border-gray-400">
                            <th className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                              {getWorkdayText(history.hour)}
                            </th>
                            <th className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                              {history.goal}
                            </th>
                            <th className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                              {history.total ?? 0}
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
