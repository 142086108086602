/**
 * Fluenta API SDK. Allows you to create instances of a client
 * with access to the Fluenta API.
 * @namespace Fluenta
 * @see Fluenta
 */

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash';

import { CURRENT_API } from '@/client';
import { Module, Organization, User } from '@/generated/graphql';

export const tokenName = 'ConfecTronic-Token';

/**
 * Configuration for the Fluenta client.
 */
interface FluentaConfig {
  /**
   * The base url of the API environment.
   */
  baseURL: string;

  /**
   * Authorization token for requests.
   */
  token?: string;

  /**
   * Default page size.
   */
  defaultPageSize?: number;
}

/**
 * Fluenta API client.
 * @class
 * @name Fluenta
 * @memberof Fluenta
 * @param {FluentaConfig} config - Client configuration parameters
 * @prop {string} config.baseURL - The base url of the API environment.
 * @prop {string} config.token - The token got from login service
 * @example
 * import { Fluenta } from '@Fluenta/client';
 * const client = new Fluenta({
 *    baseURL: 'http://54.82.180.29:8000',
 *    token: '1234567890'
 * });
 */
export class FluentaClient {
  /**
   * Axios instance.
   */
  protected readonly http: AxiosInstance;

  private readonly defaultPageSize: number;

  constructor({
    baseURL = CURRENT_API.API_SINGLE as string,
    defaultPageSize = 10,
    token = localStorage.getItem(tokenName) as string,
  }: FluentaConfig) {
    this.defaultPageSize = defaultPageSize;

    const headers: { [key: string]: string } = {
      ...{},
    };

    if (token) {
      headers['authorization'] = `Bearer ${token}`;
    }

    this.http = axios.create({
      baseURL,
      headers,
    });

    this.http.interceptors.response.use(
      function (config) {
        return config;
      },
      function (error) {
        // TODO: handle errors like 500
        // const tokenName = 'tokenFluenta';
        // const expirationName = 'expirationFluenta';
        // const userName = 'userFluenta';
        // // Do something with request error
        // localStorage.removeItem(tokenName);
        // localStorage.removeItem(expirationName);
        // localStorage.removeItem(userName);
        // window.location.pathname = '/login';
        return Promise.reject(error);
      },
    );
  }

  getAxiosInstance() {
    return this.http;
  }

  /**
   * Set the Authentication header with a valid JWT token.
   * @param token {null | string} - authentication token.
   */
  setAuthorizationHeader(token: null | string = null): void {
    const path = ['defaults', 'headers', 'common', 'Authorization'];
    if (token) _.set(this.http, path, `Bearer ${token}`);
  }

  async login(data): Promise<AxiosResponse<User>> {
    const path = '/v1/auth/login';

    const response = this.http.post(path, data);

    return response;
  }

  async createUser(data): Promise<AxiosResponse<User>> {
    const path = `/v1/users/register`;

    const response = this.http.post(path, data);

    return response;
  }

  async createOrganization(data): Promise<AxiosResponse<Organization>> {
    const path = `/v1/organizations/create`;

    const response = this.http.post(path, data);

    return response;
  }

  async getUserByEmail(email: string): Promise<AxiosResponse<User>> {
    const path = `/users/userByEmail/${email}`;

    const response = this.http.get(path);

    return response;
  }

  async getModulesByOrganization(
    organizationId: string,
  ): Promise<AxiosResponse<Module[]>> {
    const path = `/modules/byOrganization/${organizationId}`;

    const response = this.http.get(path);

    return response;
  }

  async getModuleById(id: string): Promise<AxiosResponse<Module>> {
    const path = `/modules/byId/${id}`;

    const response = this.http.get(path);

    return response;
  }

  async updateModuleById(
    id: string,
    data: Partial<Module>,
  ): Promise<AxiosResponse<Module>> {
    const path = `/modules/byId/${id}`;

    const response = this.http.put(path, data);

    return response;
  }
}
