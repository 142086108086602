import { Dialog, Transition } from '@headlessui/react';
import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, useEffect, useState } from 'react';

import { Button } from '@/components/Elements';
import {
  RestTime,
  useCreateRestTimeMutation,
  useRestTimeQuery,
} from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';

import { TimeRangePicker } from './TimeRangePicker';

type WorkdayModalProps = {
  id?: string;
  goal?: number;
  name?: string;
  total?: number;
  module: string;
  isOpen: boolean;
  update?: boolean;
  breaks: RestTime[];
  closeModal: () => void;
  onSuccess: () => void;
};

export const WorkdayModal: React.FC<WorkdayModalProps> = ({
  module,
  isOpen = false,
  breaks = [],
  closeModal,
  onSuccess,
}) => {
  const { data, refetch } = useRestTimeQuery({
    variables: {
      moduleId: module,
    },
  });
  const [createRestTime] = useCreateRestTimeMutation();

  const [breaksState, setBreaks] = useState<Partial<RestTime>[]>(breaks);
  const [start, setStart] = useState<Dayjs | null>(dayjs(new Date()));
  const [end, setEnd] = useState<Dayjs | null>(dayjs(new Date()));
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addBreak = async () => {
    try {
      setLoading(true);
      if (module) {
        const foundBreak = breaksState.find(
          (b) => start?.hour() === b.startHour,
        );

        if (foundBreak) {
          useNotificationStore.getState().addNotification({
            type: 'error',
            title: 'Error',
            message: 'Ya hay un descanso a esta hora.',
          });
          setLoading(false);
          return;
        }

        if (
          typeof start === 'undefined' ||
          !start ||
          typeof end === 'undefined' ||
          !end
        ) {
          throw new Error('No hay start ni end');
        }

        const createdRestTime = await createRestTime({
          variables: {
            data: {
              startHour: start?.hour(),
              startMin: start?.minute(),
              endHour: end?.hour(),
              endMin: end?.minute(),
              moduleId: module,
            },
          },
        });

        if (createdRestTime.data?.createRestTime) {
          fetchBreaks();
        }

        useNotificationStore.getState().addNotification({
          type: 'success',
          title: 'Éxito',
          message: 'Descanso creado',
        });
        setLoading(false);
      } else {
        throw 'Undefined module';
      }
    } catch (err) {
      setLoading(false);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: 'Descanso no se creó ' + err,
      });
    }
  };

  const fetchBreaks = async () => {
    try {
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data?.restTimes) {
      setBreaks(data?.restTimes);
    }
  }, [data]);

  useEffect(() => {
    setBreaks(breaks);
  }, [breaks]);

  // useEffect(() => {
  //   if (end && start) {
  //     if (end.isBefore(start)) {
  //       setHasError(true);
  //       setErrorMessage('La finalización de descanso no puede ser menor a la de inicio.');
  //     } else if (end.hour() > start.hour() && end?.minute() > 0) {
  //       setHasError(true);
  //       setErrorMessage(`La terminación del descanso no puede pasar de ${start.hour() + 1}:00.`);
  //     }
  //   }
  // }, [start, end]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className="fixed inset-0 z-10 overflow-y-auto">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="inset-0 min-h-screen overflow-y-auto">
          <div className="mx-auto mt-[40px] flex w-[600px] items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Descansos
                </Dialog.Title>
                <div className="mt-2">
                  <div>
                    <div className="flex">
                      <div className=" min-h-[300px]">
                        <div className="mt-4 flex flex-col">
                          {breaksState.map((b) => {
                            return (
                              <TimeRangePicker
                                currentBreak={b as RestTime}
                                key={b.id}
                                onSuccess={fetchBreaks}
                                breaks={breaksState}
                                module={module}
                              />
                            );
                          })}
                        </div>
                        <hr className="mt-8 w-full border border-solid border-black" />
                        <div className="mt-8 flex">
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label="DESDE"
                                value={start}
                                onChange={(newValue) => {
                                  setStart(newValue);
                                }}
                                onError={(error) => {
                                  if (error === 'invalidDate') {
                                    setHasError(true);
                                    setErrorMessage(
                                      'Fecha de inicio de descanso inválida',
                                    );
                                  }
                                  if (error === null) {
                                    setHasError(false);
                                    setErrorMessage('');
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <div className="mx-3">
                            <p className="relative top-3 text-2xl">-</p>
                          </div>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label="HASTA"
                                value={end}
                                onError={(error) => {
                                  if (error === 'invalidDate') {
                                    setHasError(true);
                                    setErrorMessage(
                                      'Fecha de terminación de descanso inválida',
                                    );
                                  }
                                  if (error === null) {
                                    setHasError(false);
                                    setErrorMessage('');
                                  }
                                }}
                                onChange={(newValue) => {
                                  setEnd(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <div>
                            <MuiButton
                              variant="text"
                              onClick={addBreak}
                              disabled={hasError}>
                              <p className="mt-[10px]">AGREGAR</p>
                            </MuiButton>
                          </div>
                        </div>
                        {hasError && (
                          <p className="mt-2  text-red-300">{errorMessage}</p>
                        )}
                      </div>
                    </div>

                    <div className="mt-3">
                      <Button
                        isLoading={loading}
                        onClick={() => onSuccess()}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                        Actualizar descansos
                      </Button>
                    </div>
                    <div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm text-red-900 duration-300 hover:bg-red-200"
                          onClick={closeModal}>
                          Cerrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
