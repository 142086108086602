import { lazyImport } from '@/utils/lazyImport';

const { AuthRoutes } = lazyImport(
  () => import('@/features/auth'),
  'AuthRoutes',
);
const { PrivacyPolicy } = lazyImport(
  () => import('@/features/misc'),
  'PrivacyPolicy',
);
export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
];
