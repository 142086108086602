import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import {
  useCreateModuleMutation,
  useModulesLazyQuery,
} from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';
import { useUserStore } from '@/stores/user';

const schema = z.object({
  totalOperations: z.string().min(1, 'Required'),
});

type ModuleModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess: () => void;
};

type ModuleValues = {
  totalOperations: string;
};

export const AddModuleModal: React.FC<ModuleModalProps> = ({
  isOpen = false,
  closeModal,
  onSuccess,
}) => {
  const [queryModules] = useModulesLazyQuery();
  const [createModule] = useCreateModuleMutation();

  const { user } = useUserStore();

  const [loading, setLoading] = useState(false);
  const handleCreateModule = async (values: ModuleValues) => {
    setLoading(true);
    try {
      const modules = await queryModules({
        variables: {
          organizationId: user?.organization as string,
        },
      });

      const index = modules.data?.modules.length;

      const createdModule = await createModule({
        variables: {
          data: {
            organizationId: user?.organization,
            position: index,
            totalOperations: parseInt(values.totalOperations, 10),
          },
        },
      });
      onSuccess();
      setLoading(false);
    } catch (err) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (err as Error).message,
      });
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Crear módulo
                </Dialog.Title>
                <div className="mt-2">
                  <Form<ModuleValues, typeof schema>
                    onSubmit={async (values) => {
                      handleCreateModule(values);
                    }}
                    schema={schema}>
                    {({ register, formState }) => (
                      <>
                        <InputField
                          type="text"
                          label="Cuántos puestos hay?"
                          error={formState.errors['totalOperations']}
                          registration={register('totalOperations')}
                        />
                        <div>
                          <Button
                            isLoading={loading}
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                            Crear módulo
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
