import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { AutoCompleteField, Form, InputField } from '@/components/Form';
import {
  Operation,
  Operator,
  useOperatorsQuery,
  useRestTimeLazyQuery,
  useUpdateOperationMutation,
  useUpdateOperatorMutation,
} from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';
import { useUserStore } from '@/stores/user';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  user: z.string().optional(),
  goal: z.string().min(1, 'Required'),
});

type OperationModalProps = {
  isOpen: boolean;
  id: string;
  operator: Operator;
  name: string;
  goal: number;
  closeModal: () => void;
  onSuccess: () => void;
  operation: Operation;
};

type OperationValues = {
  name: string;
  user?: string;
  goal: string;
};

export const OperationModal: React.FC<OperationModalProps> = ({
  isOpen = false,
  id,
  operator,
  goal,
  name,
  closeModal,
  onSuccess,
  operation,
}) => {
  const [queryRestTime] = useRestTimeLazyQuery();
  const [updatedOperationMutation] = useUpdateOperationMutation();
  const [updateOperatorMutation] = useUpdateOperatorMutation();

  const { user: userSession } = useUserStore();
  const { data: operatorsData } = useOperatorsQuery({
    variables: {
      organizationId: userSession?.organization as string,
    },
  });

  const [operators, setOperators] = useState<Operator[]>([]);

  const [loading, setLoading] = useState(false);

  const updateOperator = async (id: string, newOperation: string) => {
    try {
      if (newOperation) {
        const updatedOperator = await updateOperatorMutation({
          variables: {
            data: {
              id,
              operationId: newOperation,
            },
          },
        });
      }
    } catch (err) {
      console.error('error updating operator', err);
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'No se pudo actualizar usuario en operation modal.',
      });
    }
  };

  const handleEditOperation = async (values: OperationValues) => {
    setLoading(true);
    try {
      const operatorId =
        typeof values.user !== 'undefined' ? values.user : operator?.id;

      const hour = new Date().getHours();
      const restTimes = await queryRestTime({
        variables: {
          moduleId: operation?.module?.id as string,
        },
        fetchPolicy: 'no-cache',
      });
      const GOAL = parseInt(values.goal, 10);

      const currentBreak: any = restTimes.data?.restTimes.find(
        (currentBreak) => currentBreak.startHour === hour,
      );

      if (currentBreak && GOAL) {
        if (
          currentBreak.endHour === currentBreak.startHour + 1 &&
          currentBreak.endMin === 0
        ) {
          const totalMinutes = 60 - (60 - currentBreak.startMin);

          const newGoal = (totalMinutes * GOAL) / 60;

          const updatedOperation = await updatedOperationMutation({
            variables: {
              data: {
                id,
                name: values.name,
                goal: Math.ceil(newGoal),
                goalReference: parseInt(values.goal, 10),
              },
            },
            fetchPolicy: 'no-cache',
          });
          if (updatedOperation) {
            await updateOperator(operatorId as string, id as any);
          }
        } else {
          const totalMinutes =
            60 - (currentBreak.endMin - currentBreak.startMin);

          const newGoal = (totalMinutes * GOAL) / 60;

          const updatedOperation = await updatedOperationMutation({
            variables: {
              data: {
                id,
                name: values.name,
                goal: Math.ceil(newGoal),
                goalReference: parseInt(values.goal, 10),
              },
            },
            fetchPolicy: 'no-cache',
          });
          if (updatedOperation) {
            await updateOperator(operatorId as string, id as any);
          }
        }
      } else {
        const updatedOperation = await updatedOperationMutation({
          variables: {
            data: {
              id,
              name: values.name,
              goal: parseInt(values.goal, 10),
              goalReference: parseInt(values.goal, 10),
            },
          },
          fetchPolicy: 'no-cache',
        });
        if (updatedOperation) {
          await updateOperator(operatorId as string, id as any);
        }
      }

      onSuccess();
      closeModal();
      setLoading(false);
    } catch (err) {
      console.error('error creating Operation: ', err);
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: 'Hubo un error al editar la operación',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOperators = () => {
      setOperators(operatorsData?.operators as Operator[]);
    };
    if (isOpen) fetchOperators();
  }, [isOpen, operatorsData]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900">
                  Editar operación
                </Dialog.Title>
                <div className="mt-2">
                  <Form<OperationValues, typeof schema>
                    onSubmit={async (values) => {
                      handleEditOperation(values);
                    }}
                    schema={schema}>
                    {({ register, formState, control }) => {
                      return (
                        <>
                          <InputField
                            type="text"
                            label="Nombre"
                            error={formState.errors['name']}
                            registration={register('name')}
                            value={name}
                          />
                          <AutoCompleteField
                            label="Usuario"
                            error={formState.errors['user']}
                            registration={register('user')}
                            value={operator?.id}
                            options={operators?.map((operator) => ({
                              value: operator.id,
                              label: operator.name,
                            }))}
                            control={control}
                          />
                          <InputField
                            type="text"
                            label="Meta"
                            error={formState.errors['goal']}
                            registration={register('goal')}
                            value={goal}
                          />
                          <div>
                            <Button
                              isLoading={loading}
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                              Guardar
                            </Button>
                          </div>
                        </>
                      );
                    }}
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
