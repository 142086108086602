import { Dialog, Menu, Switch, Transition } from '@headlessui/react';
import {
  DeviceMobileIcon,
  EyeIcon,
  HomeIcon,
  MenuAlt2Icon,
  UserIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import moment from 'moment-timezone';
import * as React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { ROLES, useAuthorization } from '@/lib/authorization';
import { useFirebase } from '@/lib/firebase';
import { useUIStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';

import { Button } from '../Elements';
import { AddModuleModal } from '../Modals/AddModuleModal';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const SideNavigation = () => {
  const { checkAccess } = useAuthorization();
  const navigation = [
    { name: 'Dashboard', to: '.', icon: HomeIcon },
    checkAccess({ allowedRoles: [ROLES.USER, ROLES.ADMIN] }) && {
      name: 'Operadores',
      to: '/app/users',
      icon: UsersIcon,
    },
    // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
    //   name: 'Sessions',
    //   to: '/app/sessions',
    //   icon: ViewListIcon,
    // },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Phones',
      to: '/app/phones',
      icon: DeviceMobileIcon,
    },
    checkAccess({ allowedRoles: [ROLES.USER, ROLES.ADMIN] }) && {
      name: 'Preview',
      to: '/app/preview',
      icon: EyeIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Usuarios',
      to: '/app/new-user',
      icon: UserIcon,
    },
  ].filter(Boolean) as SideNavigationItem[];

  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          onClick={() => {
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
          className={clsx(
            'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group flex items-center rounded-md px-2 py-2 text-base font-medium',
          )}>
          <item.icon
            className={clsx(
              'text-gray-400 group-hover:text-gray-300',
              'mr-4 h-6 w-6 flex-shrink-0',
            )}
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { organization } = useUserStore();

  const [dateState, setDateState] = React.useState(
    moment(new Date())
      .tz(organization?.timezone as string)
      .format('LTS'),
  );
  const { logout } = useFirebase();

  const userNavigation = [
    { name: 'Perfil', to: './profile' },
    {
      name: 'Cerrar sesión',
      to: '',
      onClick: () => {
        window.localStorage.removeItem('ConfecTronic-Token');
        window.localStorage.removeItem('ConfecTronic-User');
        logout();
        window.location.replace('/');
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  React.useEffect(() => {
    const interval = setInterval(() => {
      const time = moment(new Date())
        .tz(organization?.timezone as string)
        .format('LTS');
      setDateState(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <div className="flex">
          <div className="flex items-center">
            <p className="mr-4 text-xl font-semibold">{dateState}</p>
          </div>
          <div className="flex items-center justify-center">
            <Menu.Button className="flex  max-w-xs items-center rounded-full bg-gray-200 p-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-8 w-8 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              static
              className="absolute top-11 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      )}>
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

type DrawerProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Drawer = ({ sidebarOpen, setSidebarOpen }: DrawerProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-40 flex "
        open={sidebarOpen}
        onClose={setSidebarOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}>
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex flex-shrink-0 items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 h-0 flex-1 overflow-y-auto">
              <nav className="space-y-1 px-2">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex w-64 flex-col">
        <div className="flex h-0 flex-1 flex-col">
          <div className="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4">
            <Logo />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 bg-gray-800 px-2 py-4">
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  const { organization, user } = useUserStore();
  console.log({ organization, user });
  return (
    <Link className="flex items-center text-white" to=".">
      <span className="text-xl font-semibold text-white">
        {organization?.name}
      </span>
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [isModalOpen, toggleModal] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(true);
  const { pathname } = useLocation();

  const isPreview = pathname === '/app/preview';
  const isDashboard = pathname === '/app';
  const { showActive, toggleActive } = useUIStore();

  const { organization } = useUserStore();

  const isOnboarding = pathname === '/app/onboarding';

  const onModuleCreateSuccess = () => {
    toggleModal(false);
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Drawer sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="relative z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 px-4">
            <div className="flex items-center">
              <p className="text-lg font-semibold">{organization?.name}</p>
            </div>
          </div>
          <div className="flex flex-1 justify-end px-4">
            {!isOnboarding && (
              <>
                {isDashboard && (
                  <Button
                    size="sm"
                    className="mr-10 h-[40px] self-center"
                    onClick={() => toggleModal(true)}>
                    Agregar módulo
                  </Button>
                )}
                {(isDashboard || isPreview) && (
                  <div className="self-center">
                    <Switch
                      checked={enabled}
                      onChange={(val) => {
                        setEnabled(val);
                        toggleActive();
                      }}
                      className={`${
                        enabled ? 'bg-blue-600' : 'bg-gray-200'
                      } relative inline-flex h-6 w-11 items-center rounded-full`}>
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          enabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </div>
                )}
              </>
            )}

            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main
          className="relative flex-1 overflow-y-hidden focus:outline-none"
          style={{ height: 'calc(100vh - 64px)' }}>
          {children}
        </main>
      </div>
      <AddModuleModal
        onSuccess={onModuleCreateSuccess}
        closeModal={() => toggleModal(false)}
        isOpen={isModalOpen}
      />
    </div>
  );
};
