import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import {
  RestTime,
  useDeleteRestTimeMutation,
  useUpdateRestTimeMutation,
} from '@/generated/graphql';
import { useNotificationStore } from '@/stores/notifications';

type TimeRangePickerProps = {
  module: string;
  breaks: Partial<RestTime>[];
  currentBreak: RestTime;
  onSuccess: () => void;
};
export const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  module,
  breaks,
  currentBreak,
  onSuccess,
}) => {
  const [updateRestTime] = useUpdateRestTimeMutation();
  const [deleteRestTime] = useDeleteRestTimeMutation();

  const [start, setStart] = useState<Dayjs | null>(
    dayjs(new Date())
      .set('hour', currentBreak.startHour)
      .set('minute', currentBreak.startMin),
  );
  const [end, setEnd] = useState<Dayjs | null>(
    dayjs(new Date())
      .set('hour', currentBreak.endHour)
      .set('minute', currentBreak.endMin),
  );

  const handleUpdate = async (id, data) => {
    const foundBreak = breaks.findIndex(
      (b) => data.startHour === b.startHour && id !== b.id,
    );

    if (foundBreak > -1) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: 'Ya hay un descanso a esta hora.',
      });
      setStart(
        dayjs(new Date())
          .set('hour', currentBreak.startHour)
          .set('minute', currentBreak.startMin),
      );
      return;
    }

    try {
      await updateRestTime({
        variables: {
          data: {
            restTimeId: id,
            ...data,
          },
        },
      });
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Éxito',
        message: 'Descanso Actualizado',
      });
      onSuccess();
    } catch (error) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (error as Error).message,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteRestTime({
        variables: {
          restTimeId: id,
        },
      });
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Éxito',
        message: 'Descanso Eliminado',
      });
      onSuccess();
    } catch (error) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: (error as Error).message,
      });
    }
  };
  return (
    <div className="mt-4 flex">
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="DESDE"
            value={start}
            onChange={(newValue) => {
              setStart(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <div className="mx-3">
        <p className="relative top-3 text-2xl">-</p>
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="HASTA"
            value={end}
            onChange={(newValue) => {
              setEnd(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <div>
        <MuiButton
          variant="text"
          onClick={() =>
            handleUpdate(currentBreak.id, {
              startHour: start?.hour(),
              startMin: start?.minute(),
              endHour: end?.hour(),
              endMin: end?.minute(),
            })
          }>
          <p className="mt-[10px]">EDITAR</p>
        </MuiButton>
      </div>
      <div>
        <MuiButton variant="text" onClick={() => handleDelete(currentBreak.id)}>
          <p className="mt-[10px]">BORRAR</p>
        </MuiButton>
      </div>
    </div>
  );
};
