import create from 'zustand';

import { Organization } from '@/generated/graphql';
import { ROLES } from '@/lib/authorization';

export type User = {
  uid: string;
  id: string;
  name: string;
  email: string;
  role: keyof typeof ROLES;
  organization: string;
  organizationId?: string;
  activationCode?: number;
  timeZone?: string;
};

type UserStore = {
  user: User | null;
  timeZone: string;
  organization: Partial<Organization> | null;
  setUser: (user: User) => void;
  setTimeZone: (timeZone: string) => void;
  setOrganization: (organization: Partial<Organization>) => void;
};

export const useUserStore = create<UserStore>((set) => ({
  organization: null,
  user: null,
  timeZone: 'America/Bogota',
  setUser: (user) =>
    set(() => ({
      user,
    })),
  setTimeZone: (timeZone) => set((rest) => ({ ...rest, timeZone })),
  setOrganization: (organization) =>
    set(() => ({
      organization,
    })),
}));
