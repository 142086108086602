import { ContentLayout } from '@/components/Layout';
import { useUserStore } from '@/stores/user';

export const Profile = () => {
  const { user, organization } = useUserStore();

  return (
    <ContentLayout title="Profile">
      <h1 className="mt-2 text-xl">
        Welcome <b>{`${user?.name}`}</b>
      </h1>
      <h4 className="my-3">
        Your role is : <b>{user?.role}</b>
      </h4>
      <h4 className="my-3">
        Your email is : <b>{user?.email}</b>
      </h4>
      {organization && (
        <p className="my-4">
          Your organization is: <strong>{organization.name}</strong>
        </p>
      )}
      <p>
        Activa los celulares usando el codigo:{' '}
        <strong>{user?.activationCode}</strong>
      </p>
      {/* <p className="font-medium">In this application you can:</p>
      {user?.role === ROLES.USER && (
        <ul className="my-4 list-inside list-disc">
          <li>Create comments in discussions</li>
          <li>Delete own comments</li>
        </ul>
      )}

      {(user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) && (
        <ul className="my-4 list-inside list-disc">
          <li>Create discussions</li>
          <li>Edit discussions</li>
          <li>Delete discussions</li>
          <li>Comment on discussions</li>
          <li>Delete all comments</li>
        </ul>
      )} */}
    </ContentLayout>
  );
};
