import { ClockIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { CogIcon } from '@heroicons/react/solid';
import { DocumentScanner } from '@mui/icons-material';
import {
  Avatar,
  CardHeader,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { CURRENT_API, SOCKET_IO_CONFIG } from '@/client';
import { Loading } from '@/components/Loading';
import {
  Module,
  Operation,
  RestTime,
  RestTimeQuery,
  useModuleLazyQuery,
  useRestTimeLazyQuery,
  useUpdateOperationMutation,
  useUpdateOperationsMutation,
} from '@/generated/graphql';
import { useReferenceStore } from '@/stores/reference';
import { useUIStore } from '@/stores/ui';

import { useGetModuleById } from '../hooks/useGetModuleById';
import { useUpdateModule } from '../hooks/useUpdateModule';
import { CreateModuleForm } from './CreateModuleForm';
import { EmptyOperation } from './EmptyOperation';
import OperationComponent from './Operation';
import { Reference } from './Reference';
import { ReferenceModal } from './ReferenceModal';
import { WorkdayModal } from './WorkdayModal';

export type BreaksType = {
  id: string;
  moduleId: string;
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
};

export type ModuleComponentProps = {
  moduleIndex: number;
  name: string;
  isActive: boolean;
  organization: string;
  id: string;
  timezone: string;
  onCreateModuleSuccess: () => void;
  isPreview: boolean;
  totalOperations: number;
};

const createActions = [
  {
    icon: <DocumentScanner width={20} />,
    name: 'Crear Referencia',
    type: 'reference',
  },
  { icon: <PlusIcon width={20} />, name: 'Crear Operación', type: 'operation' },
];

const settingsActions = [
  { icon: <ClockIcon width={20} />, name: 'Editar horario', type: 'schedule' },
  {
    icon: <TrashIcon width={20} />,
    name: 'Borrar módulo',
    type: 'deleteModule',
  },
];

const ModuleComponent: React.FC<ModuleComponentProps> = ({
  name,
  isActive,
  id,
  onCreateModuleSuccess,
  organization,
  timezone = '-5.0',
  isPreview = false,
  totalOperations,
  moduleIndex,
}) => {
  const { showActive } = useUIStore();

  const [queryRestTimes] = useRestTimeLazyQuery();
  const [queryModule, { loading: isModuleLoading }] = useModuleLazyQuery();
  const [updateOperation] = useUpdateOperationMutation();
  const [updateOperations] = useUpdateOperationsMutation();
  // const [updateModule] = useUpdateModuleMutation();

  const {
    data: moduleData,
    isLoading: isModuleDataLoading,
    refetch,
  } = useGetModuleById({ id });
  const { mutateAsync: updateModule } = useUpdateModule({ id });

  const [isReferenceModalOpen, toggleReferenceModal] = useState(false);
  const [isWorkdayModalOpen, toggleWorkdayModal] = useState(false);
  const [module, setModule] = useState<Module>();
  const [reference, setReference] = useState<any>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [breaks, setBreaks] = useState<RestTimeQuery['restTimes']>([]);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const { addReference, getReferenceByModuleId } = useReferenceStore();

  const handleReferenceModal = () => {
    toggleReferenceModal(true);
  };

  const handleWorkdayModal = () => {
    toggleWorkdayModal(true);
  };

  const fetchModule: any = async () => {
    try {
      let breaks;
      setLoading(true);
      if (id) {
        if (typeof moduleData === 'undefined') {
          throw new Error('Module not found');
        }

        const moduleToSet = {
          ...moduleData.data,
          operations: undefined,
        };

        const allOperations = moduleData.data.operations;

        if (
          typeof moduleData.data.goal === 'undefined' ||
          !moduleData.data.goal
        ) {
          let goal = 60;

          if (allOperations && allOperations.length > 0) {
            goal = allOperations[0].goalReference;
          }

          await updateModule({
            goal,
          });

          moduleToSet.goal = goal;
        }

        setModule(moduleToSet);

        if (allOperations) {
          if (allOperations.length) {
            const sortedOperations = [...allOperations].sort(
              (a, b) => a.position - b.position,
            );
            setOperations(sortedOperations as Operation[]);
          }
          const currentReference = allOperations.find((op) => op.isReference);
          setReference(currentReference);
          addReference({
            ...currentReference,
            operation: currentReference,
          });

          const restTimes = await queryRestTimes({
            variables: {
              moduleId: id,
            },
          });
          if (restTimes.data?.restTimes) {
            breaks = restTimes.data?.restTimes;
            setBreaks(restTimes.data?.restTimes);
          }
        }
      }
      setLoading(false);
      return {
        breaks,
      };
    } catch (err) {
      setLoading(false);
      console.error('error getting module' + (err as Error).message);
    }
  };

  const handleOnSuccess = () => {
    refetch();
  };

  const handleWorkdayOnSuccess = async () => {
    const { breaks: currentBreaks } = await fetchModule();
    toggleWorkdayModal(false);
    const hour = new Date().getHours();
    const currentHour: BreaksType = currentBreaks?.find(
      (currentBreak) => currentBreak.startHour === hour,
    );
    const GOAL = reference.goalReference;

    if (currentHour && GOAL) {
      if (
        currentHour.endHour === currentHour.startHour + 1 &&
        currentHour.endMin === 0
      ) {
        const totalMinutes = 60 - (60 - currentHour.startMin);

        const newGoal = (totalMinutes * GOAL) / 60;
        await updateOperations({
          variables: {
            data: {
              moduleId: id,
              goal: Math.ceil(newGoal),
              startedLate: false,
              currentHour: new Date().getHours(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        const totalMinutes = 60 - (currentHour.endMin - currentHour.startMin);

        const newGoal = (totalMinutes * GOAL) / 60;
        await updateOperations({
          variables: {
            data: {
              moduleId: id,
              goal: Math.ceil(newGoal),
              startedLate: false,
              currentHour: new Date().getHours(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      }
    } else {
      await updateOperations({
        variables: {
          data: {
            moduleId: id,
            goal: GOAL,
            startedLate: false,
            currentHour: new Date().getHours(),
          },
        },
        fetchPolicy: 'no-cache',
      });
    }
  };

  useEffect(() => {
    console.log({ isModuleDataLoading, moduleData });
    if (!isModuleDataLoading && moduleData) {
      fetchModule();
    }
  }, [moduleData, isModuleDataLoading]);

  /**
   * SOCKET RESTIME UPDATED
   */
  const restimeUpdatedSocket = useRef<Socket>();
  useEffect(() => {
    const RESTIME_UPDATED = 'restTimeUpdated';
    restimeUpdatedSocket.current = io(CURRENT_API.SOCKET_IO, {
      ...SOCKET_IO_CONFIG,
    });

    restimeUpdatedSocket.current.on(
      `${RESTIME_UPDATED}/${id}`,
      ({ restTimeUpdated }: { restTimeUpdated: RestTime[] }) => {
        if (restTimeUpdated.length) {
          setBreaks(restTimeUpdated as RestTime[]);
        }
      },
    );

    return () => {
      restimeUpdatedSocket.current?.disconnect();
    };
  }, []);

  /**
   * SOCKET OPERATIONS UPDATED
   */
  const updatedOperationsSocket = useRef<Socket>();
  useEffect(() => {
    const OPERATIONS_UPDATED = 'operationsUpdated';
    updatedOperationsSocket.current = io(CURRENT_API.SOCKET_IO, {
      ...SOCKET_IO_CONFIG,
    });

    updatedOperationsSocket.current.on(
      `${OPERATIONS_UPDATED}/${id}`,
      ({ operationsUpdated }: { operationsUpdated: Operation[] }) => {
        if (operationsUpdated.length) {
          const sortedOperations = [...(operationsUpdated as Operation[])].sort(
            (a, b) => a.position - b.position,
          );
          setOperations(sortedOperations as Operation[]);
        }
      },
    );

    return () => {
      updatedOperationsSocket.current?.disconnect();
    };
  }, []);

  const hasNoModules = () => {
    if (module && totalOperations === 0) {
      return true;
    }
    return false;
  };

  if (typeof module === 'undefined') {
    return (
      <div className="relative flex min-h-[300px] w-full border-b-2 border-black p-10">
        {Array.from(new Array(4)).map((_, i) => {
          return (
            <Skeleton
              key={i}
              className="mr-4"
              variant="rectangular"
              width={210}
              height={300}
            />
          );
        })}
      </div>
    );
  }

  const getCurrentScheduleText = () => {
    const hour = new Date().getHours();
    const currentBreak = breaks?.find(
      (currentBreak) => currentBreak.startHour === hour,
    );
    if (currentBreak) {
      return `Descanso de ${currentBreak.startHour}:${
        currentBreak.startMin === 0 ? '00' : currentBreak.startMin
      } a ${currentBreak.endHour}:${
        currentBreak.endMin === 0 ? '00' : currentBreak.endMin
      }`;
    } else {
      return `Desde ${hour}:00 hasta ${hour + 1}:00`;
    }
  };

  return (
    <div className="relative flex min-h-[200px] w-full border-b-2 border-black py-10 px-16 md:min-h-[320px]">
      {isModuleLoading && (
        <div className="absolute top-0 left-0 z-40 flex h-full w-full items-center justify-center bg-white bg-opacity-90">
          <Loading size={35} />
        </div>
      )}
      <div className="w-10/12 pl-4">
        {module.totalOperations > 0 && (
          <Typography variant="h5" gutterBottom>
            Módulo {name}
            {module.name ? ` - ${module.name}` : ''} -{' '}
            {getCurrentScheduleText()}
          </Typography>
        )}
        {!isActive && (
          <div>
            <Skeleton variant="rectangular" width={210} height={118} />
          </div>
        )}
        {isActive && totalOperations === 0 ? (
          <div className="h-full w-full">
            <div className="mx-auto w-full md:w-[400px]">
              <CreateModuleForm
                module={id}
                onSuccess={onCreateModuleSuccess}
                organizationId={organization}
              />
            </div>
          </div>
        ) : null}

        {isActive &&
        (totalOperations as number) > 0 &&
        operations?.length === 0 ? (
          <div
            className={cn(
              '-mx-1 h-full w-full',
              showActive ? 'mr-2 justify-end' : '',
            )}>
            <div
              className={cn(
                'box-border flex flex-wrap',
                showActive ? 'justify-end' : '',
              )}
              style={{
                transition: 'fade-in-down 250ms ease',
              }}>
              {[...new Array(totalOperations)].map((_, i) => (
                <div
                  key={i}
                  className={cn(
                    'mr-4 mb-4 w-[180px] md:w-[200px] xl:w-[230px] ',
                  )}
                  style={{
                    // transition: `transform 250ms ease`,
                    animation: 'fade-in-down 0.4s ease backwards',
                    transition: 'all 0.4s',
                  }}>
                  <Card sx={{ maxWidth: 345, overflow: 'initial' }}>
                    <CardHeader
                      avatar={
                        <Avatar
                          className="text-sm"
                          sx={{
                            bgcolor: '#ebebeb',
                            color: 'black',
                            fontSize: 14,
                          }}>
                          <p className="text-sm">
                            {name}.{i + 1}
                          </p>
                        </Avatar>
                      }
                      title={`Operación ${name}.${i + 1}`}
                      subheader={'Operación inactiva'}
                    />
                    <div className="flex w-full justify-center">
                      <div
                        className={cn(
                          'my-[8px] flex h-[100px] w-[100px] items-center justify-center rounded-full',
                          'bg-gray-400',
                        )}>
                        <p className="text-4xl">0</p>
                      </div>
                    </div>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Necesitas crear una referencia para poder empezar a usar
                        esta operación.
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {isActive &&
          (totalOperations as number) > 0 &&
          operations?.length > 0 && (
            <div
              className={cn(
                '-mx-1 h-full w-full',
                showActive ? 'mr-2 justify-end' : '',
              )}>
              <div
                className={cn(
                  'box-border flex flex-wrap',
                  showActive ? 'justify-end' : '',
                )}
                style={{
                  transition: 'fade-in-down 250ms ease',
                }}>
                {operations.length > 0 &&
                  operations.map((operation, index) => {
                    if (
                      showActive &&
                      (typeof operation?.operator?.id === 'undefined' ||
                        !operation.operator.id)
                    ) {
                      return null;
                    }

                    return (
                      <div
                        key={operation.id}
                        className={cn(
                          'mr-4 mb-4 w-[180px] md:w-[200px] xl:w-[230px] ',
                        )}
                        style={{
                          // transition: `transform 250ms ease`,
                          animation: 'fade-in-down 0.4s ease backwards',
                          transition: 'all 0.4s',
                        }}>
                        <OperationComponent
                          key={index}
                          name={name}
                          id={operation.id as string}
                          // TODO: change type
                          operation={operation as any}
                          index={index}
                          moduleIndex={moduleIndex}
                          goal={operation.goal as number}
                          total={
                            operation?.total
                              ? operation?.total
                              : reference?.total
                          }
                          completed={operation.completed as number}
                          reference={reference.id}
                          moduleId={module.id}
                          // TODO change type
                          breaks={breaks as any}
                          isPreview={isPreview}
                          operationData={operation}
                        />
                      </div>
                    );
                  })}
              </div>
              {operations.length === 0 &&
                !loading &&
                Array.from(new Array(totalOperations)).map((_, index) => {
                  return (
                    <EmptyOperation key={index} name={name} index={index} />
                  );
                })}
            </div>
          )}
      </div>

      <div className=" flex w-2/12 items-center justify-center">
        {reference && (
          <Reference
            isPreview={isPreview}
            id={reference.id}
            reference={reference}
            breaks={breaks as RestTime[]}
            module={module.id}
            onSuccess={refetch}
            moduleGoal={module?.goal as number}
            moduleName={module?.name as string}
            onSuccessModuleName={refetch}
          />
        )}
      </div>

      <div>
        {!isPreview && (
          <>
            {!hasNoModules() && (
              <div className="absolute bottom-[70px] left-0 z-0 w-full">
                <SpeedDial
                  className="absolute left-[10px] bottom-[80px]"
                  ariaLabel="SpeedDial basic example"
                  direction="right"
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                  icon={<CogIcon width={25} />}>
                  {settingsActions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        if (action.type === 'schedule') {
                          handleWorkdayModal();
                        }
                        if (action.type === 'deleteModule') {
                          alert('crear operación');
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
              </div>
            )}
          </>
        )}

        {!isPreview && (
          <>
            {!hasNoModules() && (
              <SpeedDial
                className="absolute left-[10px] z-0"
                ariaLabel="SpeedDial basic example"
                direction="right"
                sx={{ position: 'absolute', bottom: 16, right: 16, zIndex: 0 }}
                icon={<SpeedDialIcon />}>
                {createActions.map((action) => {
                  if (
                    action.type === 'reference' &&
                    typeof reference !== 'undefined'
                  )
                    return null;
                  return (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        if (action.type === 'reference') {
                          handleReferenceModal();
                        }
                        if (action.type === 'operation') {
                          alert('crear operación');
                        }
                      }}
                    />
                  );
                })}
              </SpeedDial>
            )}
          </>
        )}
      </div>
      {!isPreview && (
        <>
          {id && (
            <ReferenceModal
              module={module.id}
              isOpen={isReferenceModalOpen}
              closeModal={() => toggleReferenceModal(false)}
              onSuccess={() => handleOnSuccess()}
              // TODO change type
              operations={operations as any}
              totalOperations={totalOperations as number}
            />
          )}
          <WorkdayModal
            module={id}
            isOpen={isWorkdayModalOpen}
            closeModal={() => toggleWorkdayModal(false)}
            // TODO change type
            onSuccess={() => handleWorkdayOnSuccess()}
            breaks={breaks as any}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(ModuleComponent);
