import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';

import { Button, Table } from '@/components/Elements';
import { OperatorModal } from '@/features/misc';
import {
  Operator,
  useOperatorsQuery,
  useOperatorsUpdatedSubscription,
} from '@/generated/graphql';
import { useUserStore } from '@/stores/user';

import { DeleteOperatorrModal } from './DeleteOperator';
import { EditOperatorModal } from './EditOperatorModal';

export type OperatorsTableProps = {
  name: string;
};

export const UsersList: React.FC<OperatorsTableProps> = () => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const operatorsRef = useRef<Operator[] | null>(null);
  const { user } = useUserStore();
  const [isModalOpen, toggleModal] = useState(false);
  const [isUpdateModalOpened, toggleUpdateModal] = useState(false);
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const onOperatorCreateSuccess = () => {};

  const { data: operatorsData, refetch } = useOperatorsQuery({
    variables: {
      organizationId: user?.organization as string,
    },
  });

  const { data: operatorsUpdated } = useOperatorsUpdatedSubscription({
    variables: {
      organizationId: user?.organization as string,
    },
    shouldResubscribe: true,
    skip: typeof user?.organization === 'undefined',
    onData: ({ data }) => {
      if (
        data.data?.operatorsUpdated &&
        data.data?.operatorsUpdated.length > 0
      ) {
        setOperators(data.data?.operatorsUpdated as Operator[]);
      }
    },
  });

  useEffect(() => {
    if (operatorsData?.operators) {
      setOperators(operatorsData?.operators as Operator[]);
      operatorsRef.current = operatorsData?.operators as Operator[];
    }
  }, [operatorsData]);

  const fetchOperators = async () => {
    refetch();
  };

  const updateOperator = (id) => {
    toggleUpdateModal(true);
    setCurrentId(id);
  };

  const deleteOperation = (id) => {
    toggleDeleteModal(true);
    setCurrentId(id);
  };

  const onOperatorDeleteSuccess = () => {
    fetchOperators();
    setCurrentId(null);
  };

  return (
    <>
      <div className="w-full sm:px-6 lg:px-8">
        <div>
          <Button
            isLoading={false}
            onClick={() => toggleModal(true)}
            className="inline-flex  justify-center rounded-md border border-transparent bg-blue-100 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
            Crear operador
          </Button>
        </div>
        <div className="overflow-auto">
          <Table<Operator>
            data={operators as Operator[]}
            columns={[
              {
                title: 'Activo',
                field: 'id',
                Cell({ entry }) {
                  return (
                    <div className="flex">
                      <PencilIcon
                        width={25}
                        className="mr-4 cursor-pointer"
                        onClick={() => updateOperator(entry)}
                      />
                      <TrashIcon
                        width={25}
                        className="mr-4 cursor-pointer"
                        onClick={() => deleteOperation(entry)}
                      />
                    </div>
                  );
                  // return <p>EDITAR {id} </p>;
                },
              },
              {
                title: 'Name',
                field: 'name',
              },
              {
                title: 'Code',
                field: 'activationCode',
              },
              {
                title: 'Activo',
                field: 'active',
                Cell({ entry: { active } }) {
                  return <p>{active ? 'Activo' : 'Inactivo'}</p>;
                },
              },
              {
                title: 'Puesto',
                field: 'operation',
                Cell({ entry: { operation } }) {
                  return <p>{operation?.name}</p>;
                },
              },
              {
                title: 'ID',
                field: 'id',
              },
            ]}
          />
        </div>
      </div>
      <OperatorModal
        onSuccess={onOperatorCreateSuccess}
        organization={user?.organizationId as string}
        closeModal={() => toggleModal(false)}
        isOpen={isModalOpen}
      />
      <EditOperatorModal
        onSuccess={onOperatorCreateSuccess}
        id={currentId as any}
        closeModal={() => toggleUpdateModal(false)}
        isOpen={isUpdateModalOpened}
      />
      <DeleteOperatorrModal
        onSuccess={onOperatorDeleteSuccess}
        id={currentId as any}
        closeModal={() => toggleDeleteModal(false)}
        isOpen={deleteModal}
      />
    </>
  );
};
